import React, { useState, useEffect, useRef, setState } from 'react';

import { DataGrid } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';
import { useAuth } from "../../../hooks/AuthProvider";
import { getHeaders } from '../../../utils/apis';
import { darken, lighten, styled } from '@mui/material/styles';
import ReactDOM from 'react-dom';
import { PDFViewer } from '@react-pdf/renderer';
import ExportToPdf from '../../exporttools/ExportToPdf';
// import OrderDetails from '../orderdetail/orderDetail';
import { OrderComponentToPrint } from '../orderdetail/printOrder';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Popup from "reactjs-popup";
import { saveAs } from 'file-saver';
//import * as XLSX from 'xlsx';
import ExcelJS from "exceljs";
import ReactToPrint from 'react-to-print';
import { useReactToPrint } from 'react-to-print';
import { ClassComponent, PrintOrdersComponent } from '../printExp_/printOrderComponent';
//import ExcelJS from "exceljs/dist/es5/exceljs.browser";

const getBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

const getSelectedBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedHoverBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);



const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .super-app-theme--10': {
        backgroundColor: getBackgroundColor(theme.palette.warning.main, theme.palette.mode),

        '&:hover': {
            backgroundColor: getHoverBackgroundColor(
                theme.palette.warning.main,
                theme.palette.mode,
            ),
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(
                theme.palette.info.main,
                theme.palette.mode,
            ),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(
                    theme.palette.success.main,
                    theme.palette.mode,
                ),
            },
        },
    },
    '& .super-app-theme--20': {
        backgroundColor: getBackgroundColor(theme.palette.success.main, theme.palette.mode),

        '&:hover': {
            backgroundColor: getHoverBackgroundColor(
                theme.palette.info.main,
                theme.palette.mode,
            ),
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(
                theme.palette.info.main,
                theme.palette.mode,
            ),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(
                    theme.palette.success.main,
                    theme.palette.mode,
                ),
            },
        },
    },
    '& .super-app-theme--30': {
        backgroundColor: getBackgroundColor(
            theme.palette.secondary.main,
            theme.palette.mode,
        ),
        color: "red",
        '&:hover': {
            backgroundColor: getHoverBackgroundColor(
                theme.palette.secondary.main,
                theme.palette.mode,
            ),
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(
                theme.palette.secondary.main,
                theme.palette.mode,
            ),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(
                    theme.palette.secondary.main,
                    theme.palette.mode,
                ),
            },
        },
    },
    '& .super-app-theme--40': {
        // backgroundColor: getBackgroundColor(
        //     theme.palette.warning.main,
        //     theme.palette.mode,
        // ),
        '&:hover': {
            backgroundColor: getHoverBackgroundColor(
                theme.palette.warning.main,
                theme.palette.mode,
            ),
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(
                theme.palette.warning.main,
                theme.palette.mode,
            ),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(
                    theme.palette.warning.main,
                    theme.palette.mode,
                ),
            },
        },
    },
    '& .super-app-theme--50': {
        backgroundColor: getBackgroundColor(
            theme.palette.error.main,
            theme.palette.mode,
        ),
        '&:hover': {
            backgroundColor: getHoverBackgroundColor(
                theme.palette.error.main,
                theme.palette.mode,
            ),
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(
                theme.palette.error.main,
                theme.palette.mode,
            ),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(
                    theme.palette.error.main,
                    theme.palette.mode,
                ),
            },
        },
    },
}));

//https://mui.com/x/react-data-grid/style/


const columns = [
    { field: 'order_id', headerName: 'Order ID', width: 90 },
    { field: 'client_id', headerName: 'Client ID', width: 90 },
    {
        field: 'full_name',
        headerName: 'Full name',
        width: 150,
    },
    // {
    //     field: 'email',
    //     headerName: 'email',
    //     width: 150,
    // },
    {
        field: 'ref_number',
        headerName: 'Reference Number',
        width: 150,
    },
    {
        field: 'previous_order',
        headerName: 'Previous order',
        width: 150,
    },
    {

        field: 'quantity',
        headerName: 'Num of shirts',
        width: 100,
    }, {
        field: 'state_str',
        headerName: 'State',
        width: 100,
    }, {
        field: 'prod_batch',
        headerName: 'Prod Batch',
        width: 100,
    }, {
        field: 'items',
        headerName: 'Order Items',
        width: 100,
    }, {
        field: 'send_batch',
        headerName: 'Send Batch',
        width: 100,
    }, {
        field: 'order_time',
        headerName: 'Order time',
        width: 180,
    },
    {
        field: 'process_time',
        headerName: 'Process time',
        width: 180,
    },
    {
        field: 'usr_name',
        headerName: 'Tailor',
        width: 180,
    },

];

const columnGroupingModel = [
    {
        groupId: 'Internal',
        description: '',
        children: [{ field: 'id' }],
    },
    {
        groupId: 'Basic info',
        children: [
            {
                groupId: 'Full name',
                children: [{ field: 'last_name' }, { field: 'first_name' }],
            },
            { field: 'age' },
        ],
    },
];

export default function CheckOrders(props) {

    const [modalPB, setModalPB] = useState({ open: false });
    const [modalExportPB, setModalExportPB] = useState({ open: false });

    const [filterNewOrder, setFilterNewOrder] = useState(false);
    const [filterAccepted, setFilterAccepted] = useState(false);
    const [filterApproved, setFilterApproved] = useState(true);
    const [filterProcess, setFilterProcess] = useState(false);
    const [filterSent, setFilterSent] = useState(false);
    const [filterRequest2Cancel, setRequest2Cancel] = useState(true);
    const [filterReceived, setReceived] = useState(false);

    const [filterSelect, setFilterSelect] = useState("0");

    //const [tailorshop_id, setTailorShop] = useState("2");
    const [tailorshop, setTailorShop] = useState({ tailorshop_id: "1", short_name: "YB" });


    const componentRef = useRef();

    const [loading, setLoading] = React.useState(false);
    const [text, setText] = React.useState("old boring text");
    const onBeforeGetContentResolve = React.useRef(null);

    const handleAfterPrint = React.useCallback(() => {
        console.log("`onAfterPrint` called"); // tslint:disable-line no-console
    }, []);

    const handleBeforePrint = React.useCallback(() => {
        console.log("`onBeforePrint` called"); // tslint:disable-line no-console
    }, []);

    const reactToPrintContent = React.useCallback(() => {
        return componentRef.current;
    }, [componentRef.current]);

    React.useEffect(() => {
        if (
            text === "New, Updated Text!" &&
            typeof onBeforeGetContentResolve.current === "function"
        ) {
            onBeforeGetContentResolve.current();
        }
    }, [onBeforeGetContentResolve.current, text])

    const handleOnBeforeGetContent = React.useCallback(() => {
        console.log("`onBeforeGetContent` called"); // tslint:disable-line no-console
        setLoading(true);
        setText("Loading new text...");

        return new Promise((resolve) => {
            onBeforeGetContentResolve.current = resolve;

            setTimeout(() => {
                setLoading(false);
                setText("New, Updated Text!");
                resolve();
            }, 2000);
        });
    }, [setLoading, setText]);

    let date = new Date().toISOString().substring(0, 10);

    const handlePrint = useReactToPrint({
        content: reactToPrintContent,
        documentTitle: "ShirtOrder " + date,
        onBeforeGetContent: handleOnBeforeGetContent,
        onBeforePrint: handleBeforePrint,
        onAfterPrint: handleAfterPrint,
        removeAfterPrint: true
    });


    const [pb, setPB] = useState({ pb_code: 100, pb_date: getDate() });
    const [pb_code, setPBCode] = useState(100);


    const [searchText, setSearchText] = useState("");

    const [selOrderList, setSelOderList] = useState([]);
    const [tailorShops, setTailorShops] = useState([]);

    const [gridData, setGridData] = useState([]);

    let passdate = new Date();
    passdate.setDate(passdate.getDate() - 14);
    let newdate = new Date();
    newdate.setDate(newdate.getDate() + 1);

    const [fromDate, setFromDate] = useState(dayjs(passdate));
    const [toDate, setToDate] = useState(dayjs(newdate));

    const auth = useAuth();
    if (auth.user == null) {
        auth.logOut();
        // return;
    }
    // console.log(auth.user);

    const openModalPB = () => {
        setModalPB({ open: true });
    }
    const closeModalPB = () => {
        setModalPB({ open: false });
    }

    const openModalExportPB = () => {
        setModalExportPB({ open: true });
    }
    const closeModalExportPB = () => {
        setModalExportPB({ open: false });
    }

    const onPBChange = (e) => {
        setPB({ pb_code: e.target.value, pb_date: getDate() });
    }

    const addId = (collection, idName) => {
        return collection.map(item => {
            // console.log('item:', {
            //     ...item,
            //     id: item[idName],
            // });
            return {
                ...item,
                id: item[idName],
            };
        });
    };

    const processData = async (data) => {
        return await data.map(item => {
            let client = JSON.parse(item.data_json);
            let state_str = "";

            switch (parseInt(item['state'] + "")) {
                case 10:
                    state_str = "New order";
                    break;
                case 20:
                    state_str = "Accepted";
                    break;
                case 30:
                    state_str = "Approved";
                    break;
                case 31:
                    state_str = "Request to cancel";
                    break;
                //----------------------------
                case 40:
                    state_str = "Processed";//"Put in PB";
                    break;
                case 60:
                    state_str = "Sent"; //"Put in SB";
                    break;
                case 90:
                    state_str = "Received";
                    break;
                default:
                    state_str = item['state']
            }

            let data = JSON.parse(item['data_json']);

            // let orderItems = [];
            // fetch("/api/orderitems/" + item['order_id'], {
            //     method: "GET",
            //     headers: getHeaders(auth),
            // })
            //     .then((response) => response.json())
            //     //.then((jsonData) => addId(jsonData, "client_id"))
            //     .then((rowData) => {
            //         if (rowData["result"]) { auth.logOut(); return;}

            //         orderItems = rowData;

            //     })
            //     .catch((error) => console.log(error));

            return {
                ...item,
                id: item['order_id'],
                full_name: client['full_name'] + "",
                nickname: client['nickname'] + "",
                email: client['email'] + "",
                ref_number: (client['ref_number'] ? client['ref_number'] : ""),
                state_str: state_str,
                data: data,
                orderItems: []
            };
        });
    }

    const changeSearchText = (event) => {
        setSearchText(event.target.value);
    }

    const handleRowClick = (params) => {
        console.log('Click Row: ', params.row);
        //  props.selectClient(params.row, false);
    };

    const handleSelectionChange = (ids) => {
        const selectedIDs = new Set(ids);
        const selectedRowData = gridData.filter((row) =>
            selectedIDs.has(row.id.toString()));

        setSelOderList(selectedRowData);
        console.log(selectedRowData);
        //console.log(b);
    }

    const handleFilterSelect = (event) => {
        setFilterSelect(event.target.value);
    }

    const handleSearchBtn = () => {
        // if ((searchText == null) || searchText === "") {
        //     return;
        // }

        // console.log(JSON.stringify({
        //     filterSelect,
        //     filterNewOrder: fNewOrder,
        //     filterProcess: fProcess,
        //     filterSent,
        //     fromDate,
        //     toDate,
        //     searchText: searchText
        // }))



        fetch("/api/filterorders/", {
            method: "POST",
            headers: getHeaders(auth),
            body: JSON.stringify({

                filterNewOrder,
                filterAccepted,
                filterApproved,
                filterProcess,
                filterSent,
                filterRequest2Cancel,
                filterReceived,

                fromDate,
                toDate,
                filterSelect,
                searchText: searchText,
                tailorshop_id: tailorshop.tailorshop_id
            }),
        })
            .then((response) => response.json())
            //.then((jsonData) => addId(jsonData, "client_id"))
            .then((rowData) => {
                if (rowData["result"]) { auth.logOut(); return; }

                processData(rowData).then((rowData2) => {
                    // console.log(rowData2);
                    rowData2.map((item) => {
                        let orderItems = [];
                        fetch("/api/orderitems/" + tailorshop.tailorshop_id + "/" + item['order_id'], {
                            method: "GET",
                            headers: getHeaders(auth),

                        })
                            .then((response) => response.json())
                            //.then((jsonData) => addId(jsonData, "client_id"))
                            .then((rows) => {
                                if (rows["result"]) { auth.logOut(); }


                                //  let order = new Set(rows);
                                rows.map((o) => {
                                    let data = JSON.parse(o['data_json']);
                                    o['data'] = data;

                                    delete o['data_json'];
                                });
                                item['orderItems'] = rows;
                                item['tailorshop'] = tailorshop;
                                //  console.log("DEBUG !!", rows);

                            })
                            .catch((error) => console.log(error));
                    });
                    //setTimeout(() => { setGridData(rowData2); }, 500);
                    setGridData(rowData2);

                });
                // const rowData2 = addId(rowData, "client_id");
                // //getOrderItems(rowData2);
                // setGridData(rowData2);
            })
            .catch((error) => console.log(error));
        //fetchData();


    }

    const handlePutPB = () => {
        let list = [];
        list = selOrderList.map(function (o) {
            if (o.state === 30) // Approved
                return o.order_id;
        });
        list = list.filter(n => n)
        console.debug(list);

        if (list.length === 0) {
            alert("You need to select at least one approved order");
            return;
        }

        openModalPB();
    }

    const handlePutPBConfirm = () => {
        let list = [];
        list = selOrderList.map(function (o) {
            if (o.state === 30)
                return o.order_id;
        });
        list = list.filter(n => n)

        if (list.length === 0) {
            alert("You need to select at least one approved order");
            return;
        }

        fetch("/api/putinpb/", {
            method: "POST",
            headers: getHeaders(auth),
            body: JSON.stringify({
                pb_code: pb.pb_code,
                tailorshop_id: tailorshop.tailorshop_id,
                order_list: list,
            }),
        })
            .then((response) => response.json())
            //.then((jsonData) => addId(jsonData, "client_id"))
            .then((rowData) => {
                if (rowData["result"]) { auth.logOut(); return; }
                else {
                    alert("Put " + list.length + " order(s) in PB successful");
                    closeModalPB();
                }
                handleSearchBtn();
            })
            .catch((error) => console.log(error));
        //fetchData();
    }

    function getDate() {
        let date = new Date();
        const offset = date.getTimezoneOffset()
        date = new Date(date.getTime() - (offset * 60 * 1000))
        return date.toISOString().split('T')[0]
    }

    const xls_border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };

    const xls_alignment = { vertical: 'top', horizontal: 'left' };

    async function saveAsExcel(pb, prefix) {
        const workbook = new ExcelJS.Workbook();
        workbook.creator = 'Shirt.vn';
        workbook.lastModifiedBy = 'Admin';
        workbook.created = new Date();
        workbook.modified = new Date();
        workbook.lastPrinted = new Date();
        workbook.calcProperties.fullCalcOnLoad = true;
        workbook.views = [
            {
                x: 0, y: 0, width: 10000, height: 20000,
                firstSheet: 0, activeTab: 1, visibility: 'visible'
            }
        ]

        // DOC
        let sheet = workbook.addWorksheet('DOC ' + pb.pb_code, { views: [{ showGridLines: true }] });

        let customCell = sheet.getCell("A2");
        customCell.font = {
            name: "Arial",
            family: 4,
            size: 20,
            underline: false,
            bold: true
        };
        customCell.value = "Product Batch :" + pb.pb_code;

        customCell = sheet.getCell("A3");
        customCell.font = {
            name: "Arial",
            family: 4,
            size: 20,
            underline: false,
            bold: true
        };
        customCell.value = " Ngày " + pb.pb_date;

        sheet.addRow();

        let header = sheet.addRow();
        header.font = {
            name: "Arial",
            family: 4,
            size: 12,
            underline: false,
            bold: true
        };

        header.getCell(1).value = "Mã đơn";
        header.getCell(2).value = "SL";
        header.getCell(3).value = "Ghi chú";
        header.getCell(4).value = "Vải";

        sheet.getColumn(1).width = 10;
        sheet.getColumn(2).width = 15;
        sheet.getColumn(3).width = 15;
        sheet.getColumn(4).width = 40;

        header.getCell(1).border = xls_border;
        header.getCell(2).border = xls_border;
        header.getCell(3).border = xls_border;
        header.getCell(4).border = xls_border;


        await fetch("/api/order_sum_pb/" + tailorshop.tailorshop_id + "/" + pb.pb_code, {
            method: "GET",
            headers: getHeaders(auth),
        })
            .then((response) => response.json())
            //.then((jsonData) => addId(jsonData, "client_id"))
            .then((rowData) => {
                if (rowData["result"]) { auth.logOut(); return; }

                console.log(rowData);

                let count = 0;

                rowData.forEach(rec => {
                    let row = sheet.addRow();

                    row.getCell(1).value = rec['order_prefix'] + rec['order_id'];
                    row.getCell(2).value = rec['quantity'];
                    // row.getCell(3).value = rec['notes'];
                    row.getCell(4).value = rec['fabric_code'];

                    count = count + rec['quantity'];
                    //Style

                    for (let j = 1; j <= 4; j++) {
                        row.getCell(j).border = xls_border;
                        row.getCell(j).alignment = xls_alignment;
                    }
                    row.getCell(2).alignment = { vertical: 'top', horizontal: 'right' };
                });

                let row = sheet.addRow();
                row.getCell(2).value = count;

            })
            .catch((error) => console.log(error));

        // NGANG
        sheet = workbook.addWorksheet('NGANG ' + pb.pb_code, { views: [{ showGridLines: true }] });

        customCell = sheet.getCell("A2");
        customCell.font = {
            name: "Arial",
            family: 4,
            size: 20,
            underline: false,
            bold: true
        };
        customCell.value = prefix + "-" + pb.pb_code;

        customCell = sheet.getCell("A3");
        customCell.font = {
            name: "Arial",
            family: 4,
            size: 20,
            underline: false,
            bold: true
        };
        customCell.value = " Ngày " + pb.pb_date;

        sheet.addRow();

        header = sheet.addRow();
        header.font = {
            name: "Arial",
            family: 4,
            size: 12,
            underline: false,
            bold: true
        };

        header.getCell(1).value = "Mã đơn";
        header.getCell(2).value = "Phân loại";
        header.getCell(3).value = "Vải";
        header.getCell(4).value = "SL";
        header.getCell(5).value = "Ngày cắt";
        header.getCell(6).value = "Ngày xuất";
        header.getCell(7).value = "Ghi chú";

        sheet.getColumn(1).width = 10;
        sheet.getColumn(2).width = 15;
        sheet.getColumn(3).width = 15;
        sheet.getColumn(4).width = 20;
        sheet.getColumn(5).width = 20;
        sheet.getColumn(6).width = 20;
        sheet.getColumn(7).width = 20;

        for (let j = 1; j <= 7; j++) {
            header.getCell(j).border = xls_border;
        }


        await fetch("/api/order_detail_pb/" + tailorshop.tailorshop_id + "/" + pb.pb_code, {
            method: "GET",
            headers: getHeaders(auth),
        })
            .then((response) => response.json())
            //.then((jsonData) => addId(jsonData, "client_id"))
            .then((rowData) => {
                if (rowData["result"]) { auth.logOut(); return; }

                console.log(rowData);
                let rowidx = 5;
                let lastorder_id = rowData[0].order_id;
                let startrow = 5;
                let mergeList = [];
                let count = 0;
                rowData.forEach(rec => {
                    let row;
                    if (rec['order_id'] !== lastorder_id) {
                        // console.debug('lastorder:', { lastorder_id, startrow, rowidx, order_id: rec['order_id'] });
                        if (rowidx - startrow >= 2) {
                            console.debug('merge: ', 'A' + startrow + ':A' + (rowidx - 1));
                            mergeList.push('A' + startrow + ':A' + (rowidx - 1))
                            //sheet.mergeCells('A' + startrow + ':A' + rowidx - 1);
                        }
                        startrow = rowidx;
                        lastorder_id = rec['order_id'];
                    }

                    count = count + rec['quantity'];

                    for (let i = 0; i < rec['quantity']; i++) {
                        row = sheet.addRow(rowidx + i);
                        row.getCell(1).value = rec['order_prefix'] + rec['order_id'];
                        row.getCell(3).value = rec['fabric_code'];
                        row.getCell(4).value = 1;
                        // row.getCell(7).value = rec['notes'];

                        // Style
                        for (let j = 1; j <= 7; j++) {
                            row.getCell(j).border = xls_border;
                            row.getCell(j).alignment = xls_alignment;
                        }
                        row.getCell(4).alignment = { vertical: 'top', horizontal: 'right' };

                        rowidx = rowidx + 1;
                    }

                });

                let row = sheet.addRow();
                row.getCell(4).value = count;
                //sheet.mergeCells('A5:A7');
                mergeList.forEach((str) => {
                    //console.debug(str);
                    sheet.mergeCells(str);
                });



            })
            .catch((error) => console.log(error));


        // Save file
        let timeSpan = new Date().toISOString();
        let fileName = `${prefix}-${pb.pb_code}`;

        workbook.xlsx.writeBuffer().then(function (buffer) {
            saveAs(
                new Blob([buffer], { type: "application/octet-stream" }),
                `${fileName}.xlsx`
            );
        });

    }

    // const exportToExcel = (tableId, name) => {
    //     let timeSpan = new Date().toISOString();
    //     let prefix = name || "ExportResult";
    //     let fileName = `${prefix}-${timeSpan}`;
    //     //let targetTableElm = document.getElementById(tableId);
    //     let ws = XLSX.utils.json_to_sheet([{ a: "a", b: "c" }, { a: "2", b: "1" },], { sheet: prefix });
    //     let wb = XLSX.utils.book_new(ws);
    //     XLSX.writeFile(wb, `${fileName}.xlsx`);
    // }

    const handleExportPB = () => {
        openModalExportPB();
    }


    const handleExportPBConfirm = () => {

        saveAsExcel(pb, tailorshop.short_name);

        closeModalExportPB();
    }

    const handlePrintOrder = () => {
        if (selOrderList.length === 0) {
            alert("You need to select at least one order");
            return;
        }
        handlePrint();
    }

    const handlePrintLabel = () => {
        if (selOrderList.length === 0) {
            alert("You need to select at least one order");
            return;
        }
    }

    const handleAcceptCancel = () => {
        if (selOrderList.length === 0) {
            alert("You need to select at least one order");
            return;
        }

        selOrderList.forEach((e) => {
            if (e.state === 31) {
                fetch("/api/order/" + e.order_id, {
                    method: "DELETE",
                    headers: getHeaders(auth),
                    //body: JSON.stringify(data),
                })
                    .then((response) => response.json())
                    //.then((jsonData) => addId(jsonData, "client_id"))
                    .then((data) => {
                        console.debug("data", data);
                        if (data["result"] === "ok") {
                            alert('Order had been removed');
                            // navigate(-1);
                        } else if (data["result"]) {
                            auth.logOut();
                        } else {
                            //Nothing
                        }
                    })
                    .catch((error) => console.log(error));
            }
        });

    }




    const handleFilterNewOrder = (event) => {
        if (!filterNewOrder || filterAccepted || filterApproved || filterProcess || filterSent || filterRequest2Cancel || filterReceived) {
            setFilterNewOrder(event.target.checked);
        }
    }

    const handleFilterAccepted = (event) => {
        if (filterNewOrder || !filterAccepted || filterApproved || filterProcess || filterSent || filterRequest2Cancel || filterReceived) {
            setFilterAccepted(event.target.checked);
        }
    }
    const handleFilterApproved = (event) => {
        if (filterNewOrder || filterAccepted || !filterApproved || filterProcess || filterSent || filterRequest2Cancel || filterReceived) {
            setFilterApproved(event.target.checked);
        }
    }

    const handleFilterProcess = (event) => {
        if (filterNewOrder || filterAccepted || filterApproved || !filterProcess || filterSent || filterRequest2Cancel || filterReceived) {
            setFilterProcess(event.target.checked);
        }

    }

    const handleFilterSent = (event) => {
        if (filterNewOrder || filterAccepted || filterApproved || filterProcess || !filterSent || filterRequest2Cancel || filterReceived) {
            setFilterSent(event.target.checked);
        }
    }

    const handleFilterRequest2Cancel = (event) => {
        if (filterNewOrder || filterAccepted || filterApproved || filterProcess || filterSent || !filterRequest2Cancel || filterReceived) {
            setRequest2Cancel(event.target.checked);
        }
    }

    const handleReceived = (event) => {
        if (filterNewOrder || filterAccepted || filterApproved || filterProcess || filterSent || filterRequest2Cancel || !filterReceived) {
            setReceived(event.target.checked);
        }
    }



    const handleTailorShopSelect = (event) => {

        let ts = tailorShops[event.target.value];
        if (ts) {
            console.log('TS:', ts);
            setTailorShop(ts);

            fetch("/api/lastpb/" + ts.tailorshop_id, { //tailorshop
                method: "GET",
                headers: getHeaders(auth),
            })
                .then((response) => response.json())
                //.then((jsonData) => addId(jsonData, "client_id"))
                .then((rowData) => {
                    if (rowData["result"]) { auth.logOut(); return; }
                    //getOrderItems(rowData2);

                    if (rowData.length === 0) {
                        setPB({ pb_code: "1", pb_date: getDate() });
                    } else {
                        console.log(rowData);
                        setPB(rowData);

                    }
                    //        handleSearchBtn();

                })
                .catch((error) => console.log(error));
        }
    }


    useEffect(() => { handleSearchBtn(); }, [tailorshop, filterNewOrder, filterAccepted, filterApproved, filterProcess, filterSent, filterRequest2Cancel, filterReceived]);


    useEffect(() => {

        if (!auth.user) {
            auth.logOut();
            return;
        }


        // alert("go");

        fetch("/api/tailorshops/", {
            method: "GET",
            headers: getHeaders(auth),
        })
            .then((response) => response.json())
            //.then((jsonData) => addId(jsonData, "client_id"))
            .then((rowData) => {
                if (rowData["result"]) { auth.logOut(); return; }
                //getOrderItems(rowData2);
                //console.log(rowData);
                setTailorShops(rowData);

                if (rowData.length >= 1) {
                    setTailorShop(rowData[0]);
                }

            })
            .catch((error) => console.log(error));

        fetch("/api/lastpb/" + tailorshop.tailorshop_id, { //tailorshop
            method: "GET",
            headers: getHeaders(auth),
        })
            .then((response) => response.json())
            //.then((jsonData) => addId(jsonData, "client_id"))
            .then((rowData) => {
                if (rowData["result"]) { auth.logOut(); return; }
                //getOrderItems(rowData2);
                // console.log('Get LastPB', rowData);
                // setPB(rowData.pb_code);

                if (rowData.length === 0) {
                    setPB({ pb_code: "1", pb_date: getDate() });
                } else {
                    console.log(rowData);
                    setPB(rowData);

                }

            })
            .catch((error) => console.log(error));



    }, []);

    return (<>
        <div className="container p-0">
            <div className="m-1 row p-0">
                <div className="row-sm m-1" style={{ flexFlow: "row", display: "flex" }}>
                    <label className="form-select-label p-1" htmlFor="tailorshop"> Tailor shop:</label>
                    <select className="form-select m-2 " aria-label="Tailor shop" style={{ height: "55px", width: "450px" }} id="tailorshop"
                        onChange={handleTailorShopSelect}>
                        {/* <option value="0" id="0" defaultValue>All</option> */}

                        {tailorShops.map((shop, idx) => {
                            return (
                                <option value={idx} key={shop.tailorshop_id}>{shop.tailorshop_id} - {shop.name} ({shop.short_name})</option>
                            );
                        })}
                    </select>
                </div>
            </div>
            <div className="m-1 row ">
                <div className="col">
                    <button type="submit" className="btn btn-primary mx-2" style={{ height: "50px" }}
                        onClick={handlePutPB}>Put in Prod Batch</button>
                    <button type="submit" className="btn btn-warning mx-2" style={{ height: "50px" }}
                        onClick={handleExportPB}>Export Prod Batch</button>
                    <button type="submit" className="btn btn-success mx-2" style={{ height: "50px" }}
                        onClick={handlePrintOrder}>Print order</button>
                    <button type="submit" className="btn btn-success mx-2" style={{ height: "50px" }}
                        onClick={handlePrintLabel}>Print label</button>
                    <button type="submit" className="btn btn-danger mx-2" style={{ height: "50px" }}
                        onClick={handleAcceptCancel}>Approve Cancel</button>
                </div>
                <div>
                    <Popup
                        open={modalPB.open}
                        closeOnDocumentClick
                        onClose={closeModalPB}

                    >
                        <div className="modal-reset p-2">
                            <button className="close" onClick={closeModalPB}>
                                &times;
                            </button>
                            <div className="modal-content p-2" >
                                <div className="modal-heading-box">
                                    <h1 className="modal-heading">Enter Production Batch</h1>
                                </div>
                                <div className="modal-form ">

                                    <div className="modal-input-box">
                                        <div className="form-group p-3">
                                            <label forhtml="pb1">{tailorshop.short_name}-</label>
                                            <input className="modal-input" type="text" id="pb1" value={pb.pb_code} onChange={onPBChange} />
                                        </div>
                                        <button className="modal-button btn btn-primary" type="submit" onClick={handlePutPBConfirm}> Put in PB </button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Popup>

                    <Popup
                        open={modalExportPB.open}
                        closeOnDocumentClick
                        onClose={closeModalExportPB}
                    >
                        <div className="modal-reset p-2">
                            <button className="close" onClick={closeModalExportPB}>
                                &times;
                            </button>
                            <div className="modal-content p-2">
                                <div className="modal-heading-box">
                                    <h1 className="modal-heading">Enter Production Batch to export</h1>
                                </div>
                                <div className="modal-form ">
                                    <div className="modal-input-box">
                                        <div className="form-group  p-3">
                                            <label forhtml="pb2">{tailorshop.short_name}-</label>
                                            <input className="modal-input" type="text" id="pb2" value={pb.pb_code} onChange={onPBChange} />
                                        </div>
                                        <button className="modal-button btn btn-primary" type="submit" onClick={handleExportPBConfirm}> Export to Excel file </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Popup>
                </div>
            </div>
            <div className="m-1 row p-0">
                <div className="col">
                    <div className="container p-0">
                        <div className="row-sm m-1 " style={{ flexFlow: "row", display: "flex" }}>

                            <select className="form-select m-2 " aria-label="Select field to search and filter"
                                style={{ height: "55px", width: "450px" }}
                                onChange={handleFilterSelect} >
                                <option value="0" defaultValue>No filter</option>
                                <option value="1">1. Order ID</option>
                                <option value="2">2. Client ID</option>
                                <option value="3">3. Full name</option>
                                <option value="4">4. Reference number</option>
                                <option value="5">5. Fabric</option>
                                <option value="6">6. Email</option>
                                <option value="7">7. Phone number</option>
                                <option value="8">8. Prod Batch</option>
                                <option value="9">9. Send Batch</option>
                            </select>

                            <input type="text" className="form-control m-2" id="searchText"
                                value={searchText} placeholder="Enter text"
                                onChange={changeSearchText}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter")
                                        handleSearchBtn();
                                }} />
                            <span><button type="button" className="btn btn-success m-2" onClick={handleSearchBtn}><SearchIcon /></button></span>

                        </div>
                        <div className="row-sm m-1" style={{ flexFlow: "row", display: "flex" }}>
                            <div className="form-check form-check-inline m-1">
                                <input className="form-check-input" type="checkbox" id="state1" checked={filterNewOrder} onChange={handleFilterNewOrder} />
                                <label className="form-check-label p-1" htmlFor="state1"> New</label>
                            </div>
                            <div className="form-check form-check-inline m-1">
                                <input className="form-check-input" type="checkbox" id="state2" checked={filterAccepted} onChange={handleFilterAccepted} />
                                <label className="form-check-label p-1" htmlFor="state2"> Accepted</label>
                            </div>
                            <div className="form-check form-check-inline m-1">
                                <input className="form-check-input" type="checkbox" id="state3" checked={filterApproved} onChange={handleFilterApproved} />
                                <label className="form-check-label p-1" htmlFor="state3"> Approved</label>
                            </div>
                            <div className="form-check form-check-inline m-1">
                                <input className="form-check-input" type="checkbox" id="state4" checked={filterProcess} onChange={handleFilterProcess} />
                                <label className="form-check-label p-1" htmlFor="state4"> Processed</label>
                            </div>
                            <div className="form-check form-check-inline m-1">
                                <input className="form-check-input" type="checkbox" id="state5" checked={filterSent} onChange={handleFilterSent} />
                                <label className="form-check-label p-1" htmlFor="state5"> Sent</label>
                            </div>
                            <div className="form-check form-check-inline m-1">
                                <input className="form-check-input" type="checkbox" id="state6" checked={filterRequest2Cancel} onChange={handleFilterRequest2Cancel} />
                                <label className="form-check-label p-1" htmlFor="state6"> Request to cancel</label>
                            </div>
                            <div className="form-check form-check-inline m-1">
                                <input className="form-check-input" type="checkbox" id="state7" checked={filterReceived} onChange={handleReceived} />
                                <label className="form-check-label p-1" htmlFor="state7"> Received</label>
                            </div>

                            <LocalizationProvider dateAdapter={AdapterDayjs}>

                                <DatePicker label="From Date"
                                    value={fromDate}
                                    onChange={(newValue) => setFromDate(newValue)} />&nbsp;
                                <DatePicker label="To Date"
                                    value={toDate}
                                    onChange={(newValue) => setToDate(newValue)} />

                            </LocalizationProvider>
                        </div>


                    </div>
                </div>


            </div>
            <div className="m-1 row p-0">
                <div className="col">


                </div>
            </div>
            <StyledDataGrid
                rows={gridData}
                columns={columns}
                checkboxSelection={true}
                disableMultipleRowSelection={true}
                columnGroupingModel={columnGroupingModel}
                density='compact'
                //  onRowClick={handleRowClick}
                onRowSelectionModelChange={handleSelectionChange}

                pageSizeOptions={[5, 10, 25, 50, 100]}
                initialState={{
                    pagination: {
                        paginationModel: { pageSize: 25, page: 0 },
                    },
                }}
                getRowClassName={(params) => `super-app-theme--${params.row.state}`}
            />
            Total: {gridData.length} row(s)

            <div>
                {/* <PDFViewer style={{ width: "100%", height: "600px" }}>
                    <ExportToPdf />
                </PDFViewer> */}
                {/* <div style={{ visibility: "hidden" }}> */}
                {/* <button onClick={handlePrint} className="btn btn-primary">Print Orders</button> */}
                {/* <OrderDetails orderList={selOrderList} ref={componentRef} /> */}

                <OrderComponentToPrint ref={componentRef} orderList={selOrderList} />
                {/* </div> */}

            </div>




        </div >
        {/* DEBUG: {JSON.stringify(tailorShops)} */}
    </>
    );

}

