import React, { useState, useEffect, useRef, setState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from "react-router-dom";


import ReactDatePicker from "react-datepicker";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ReactFlagsSelect from "react-flags-select";
import { useAuth } from "../../hooks/AuthProvider";
import { getHeaders } from '../../utils/apis';
import { DoModal, ModalContent } from "../../utils/Modal";

import './AddModRole.css';


export default function AddModRole(props) {
    const { register, handleSubmit, control, watch, formState: { errors }, reset } = useForm();

    const navigate = useNavigate();

    const auth = useAuth();
    if (!auth.user) {
        auth.logOut();

    }


    const onSubmit = (data) => {
        if (!auth.user) {
            auth.logOut();
            return;
        }

        console.debug("DEBUG:", data);

        fetch("/api/checkexists", {
            method: "POST",
            headers: getHeaders(auth),
            body: JSON.stringify({
                name: data['role_name'],
                type: "roles"
            }),
        }).then((response) => response.json())
            .then((rowData) => {
                if (rowData["result"]) {
                    auth.logOut();
                }
                console.log('Check', rowData);
                if (rowData.length >= 1) {

                    alert("Role " + data['role_name'] + " already existed");
                    return;
                }

                // Add new user
                fetch("/api/addrole", {
                    method: "POST",
                    headers: getHeaders(auth),
                    body: JSON.stringify(data),
                })
                    .then((response) => response.json())
                    .then((rowData) => {
                        if (rowData["result"]) {
                            if (rowData["result"] === "timeout") {
                                auth.logOut();
                            } else if (rowData["result"] === "OK") {
                                console.log("Add new return:", rowData[0]);

                                alert("Add role successful");
                                //props.newclientUpdate(data, true);
                                reset();
                                navigate(-1);
                            } else {

                                alert("Error when query DB");
                            }
                        } else {
                            console.log("Add new role return:", rowData[0]);

                            alert("Add role successful");
                            //props.newclientUpdate(data, true);
                            reset();
                        }
                        // console.log(rowData);
                    })
                    .catch((error) => { console.log(error); alert("Error call API"); });
            })
            .catch((error) => { console.log(error); alert("Error call API"); });

    }

    useEffect(() => {

        if (!auth.user) {
            auth.logOut();
            return;
        }




    }, []);


    return (
        <div className="d-flex justify-content-center">
            <form onSubmit={handleSubmit(onSubmit)}>
                <Container className="p-9 ">



                    <Row style={{ width: "100%" }}>

                        <div className="mb-3">
                            <label htmlFor="role_name">Role Name *:</label>&nbsp;<br />
                            <input id="role_name" type="text" placeholder="Role name" {...register("role_name", { required: true })} />
                        </div>

                        <div className="mb-3">
                            <label htmlFor="notes">Notes:</label>&nbsp;<br />

                            <textarea id="notes" name="notes" rows="3" cols="20" {...register("notes", {})}>
                            </textarea>
                        </div>

                    </Row>

                </Container>
                <button type="submit" className="btn btn-primary btn-sm">Add Role</button>

            </form>
        </div>
    );
}




