

import React, { useState } from 'react';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Tabs from '@mui/material/Tabs';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';

import './ClientInfo.css';
import ClientsMan from '../clientman/ClientsMan';
import { Margin, Padding } from '@mui/icons-material';
import ClientOrders from '../clientorders/ClientOrders';
import ClientMeasurements from '../clientmeasure/clientmeasure';


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function ClientProfile() {
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const [clientData, setClientData] = useState();
    const onSubmit = data => console.log(data);

    const [tabValue, setTabValue] = useState(0);

    const childRef = useRef();

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const selectClient = (data, next) => {
        setClientData({ ...data });
        setValue("client_id", data['client_id']);
        setValue("first_name", data['first_name']);
        setValue("last_name", data['last_name']);
        setValue("full_name", data['full_name']);
        setValue("nickname", data['nickname']);
        setValue("email", data['email']);
        setValue("telephone", data['telephone']);
        setValue("dob", data['dob']);
        setValue("country", data['country']);
        setValue("address1", data['address1']);
        setValue("remark", data['remark']);
        setValue("state", data['state']);

        childRef.current?.callReloadData(data['client_id']);


    }

    return (
        <div style={{ margin: "30px" }}>
            <ClientsMan selectClient={selectClient} />
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Profile" {...a11yProps(0)} style={{ textTransform: 'none', }} />
                        <Tab label="Order List" {...a11yProps(1)} style={{ textTransform: 'none', }} />
                        <Tab label="Measurements" {...a11yProps(2)} style={{ textTransform: 'none', }} />
                    </Tabs>
                </Box>
                <CustomTabPanel value={tabValue} index={0}>
                    <h3> Profiless</h3>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Container className="p-3 ">
                            <Row style={{ width: "100%" }}>
                                <Col md="6">
                                    <div className="mb-3">
                                        <label>Client ID:</label>&nbsp;<br />
                                        <input type="text" placeholder="client_id" {...register("client_id", { required: true })} />
                                    </div>
                                    <div className="mb-3">
                                        <label>First Name:</label>&nbsp;<br />
                                        <input type="text" placeholder="first_name" {...register("first_name", {})} />
                                    </div>
                                    <div className="mb-3">
                                        <label>Last Name:</label>&nbsp;<br />
                                        <input type="text" placeholder="last_name" {...register("last_name", {})} />
                                    </div>
                                    <div className="mb-3">
                                        <label>Full Name:</label>&nbsp;<br />
                                        <input type="text" placeholder="full_name" {...register("full_name", { required: true })} />
                                    </div>
                                    <div className="mb-3">
                                        <label>Nickname:</label>&nbsp;<br />
                                        <input type="text" placeholder="nickname" {...register("nickname", {})} />
                                    </div>
                                    <div className="mb-3">
                                        <label>Email:</label>&nbsp;<br />
                                        <input type="email" placeholder="email" {...register("email", { required: true })} />
                                    </div>
                                </Col>
                                <Col md="6">
                                    <div className="mb-3">
                                        <label>Telephone:</label>&nbsp;<br />
                                        <input type="text" placeholder="telephone" {...register("telephone", {})} />
                                    </div>
                                    <div className="mb-3">
                                        <label>Date of birth:</label>&nbsp;<br />
                                        <input type="text" placeholder="dob" {...register("dob", {})} />
                                    </div>
                                    <div className="mb-3">
                                        <label>Country:</label>&nbsp;<br />
                                        <input type="text" placeholder="country" {...register("country", {})} />
                                    </div>
                                    <div className="mb-3">
                                        <label>Address:</label>&nbsp;<br />
                                        <input type="text" placeholder="address" {...register("address1", {})} />
                                    </div>
                                    <div className="mb-3">
                                        <label>Remark:</label>&nbsp;<br />
                                        <input type="text" placeholder="remark" {...register("remark", {})} />
                                    </div>
                                    <div className="mb-3">
                                        <label>Status:</label>&nbsp;<br />
                                        <select {...register("state")}>
                                            <option value="1">Active</option>
                                            <option value="2">Lock</option>
                                            {/* <option value="3">Wait approved</option> */}
                                            <option value="9">Deleted</option>
                                        </select>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </form>
                </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={1}  >
                    <h3> Order list</h3>
                    <ClientOrders clientData={clientData} ref={childRef} />
                </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={2}>
                    <h3> Measurements</h3>
                    <ClientMeasurements clientData={clientData} ref={childRef} />

                </CustomTabPanel>

            </Box>




        </div >
    );
}




