import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Avatar from '@mui/material/Avatar';
import { useAuth } from "../../hooks/AuthProvider";
import { getHeaders } from '../../utils/apis';
import * as Icon from 'react-bootstrap-icons';
import './MainNavbar.css';
import { web_timeout } from '../../global';

const MainNavbar = () => {
    const [sysMenu, setSysMenu] = useState([]);
    const auth = useAuth();


    useEffect(() => {


        //  console.debug("User", auth.user);
        //  alert("Debug");

        if (auth.user) {
            let seconds = (Date.now() - auth.user.timestamp) / 1000;
            if (seconds > web_timeout) {
                auth.logOut();
            } else {
                setSysMenu(auth.user.sysMenu);
            }
        }


    }, []);

    return (
        <>
            <Navbar expand="lg" className="bg-body-tertiary">
                <Container>


                    <Navbar.Brand href="/"><b style={{ color: "red" }}>FACTORY</b>
                        {/* <img src="./static/images/logoshirt.webp" alt="shirt.vn" style={{ width: "90px" }} /> */}
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">

                            {auth.user && <>

                                {sysMenu.map((m) => {
                                    return (

                                        m.menu_type === 1 ?
                                            <NavDropdown title={m.menu_name} key={m.menu_id} id={m.menu_id} style={{ paddingInline: "25px", fontSize: "14px" }}>
                                                {m.sub_menu.map((sm) => (
                                                    sm.menu_type === 2 ?
                                                        <NavDropdown.Item href={sm.menu_link} key={sm.menu_id}><i className="bi bi-bag-check"></i> {sm.menu_name} </NavDropdown.Item>
                                                        :
                                                        <NavDropdown.Divider key={sm.menu_id} />
                                                ))}
                                            </NavDropdown>
                                            : <Nav.Link href={m.menu_link} key={m.menu_id} style={{ paddingInline: "25px", whiteSpace: 'nowrap', fontSize: "14px" }}> {m.menu_name}</Nav.Link>
                                    )
                                })}

                            </>}
                            {/* 
                        <Nav.Link href="/about" style={{ whiteSpace: 'nowrap' }}> Contact us</Nav.Link> */}
                        </Nav>
                        <Navbar.Toggle />
                        <Navbar.Collapse className="justify-content-end">
                            {auth.user ? <>



                                <Avatar alt={auth.user.usr_name} src={`${auth.user['avatar']}`} />&nbsp;
                                <a href="/profile">{auth.user.usr_name}</a> /&nbsp;

                                <Nav.Link onClick={() => auth.logOut()} style={{ whiteSpace: 'nowrap' }}> Logout</Nav.Link>
                            </> : <>
                                <Nav.Link href="/login"> Login</Nav.Link>
                            </>
                            }
                        </Navbar.Collapse>
                    </Navbar.Collapse>
                </Container>
            </Navbar >
            <hr style={{ marginTop: "0px" }} />
        </>
    );
}

export default MainNavbar;
