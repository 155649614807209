import React, { useState, useEffect } from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import Rating from '@mui/material/Rating';
import { useAuth } from "../hooks/AuthProvider";
import { getHeaders } from '../utils/apis';
import { DoModal, ModalContent } from "../utils/Modal";
import { num2strLeadingZeros } from '../utils/utils';
import './AvatarSelect.css';
import { styled } from '@mui/material';

export default function AvatarSelect(props) {

    const [selectedAvatar, setSelectedAvatar] = useState({});
    const [avatarList, setAvatarList] = useState([]);

    const auth = useAuth();
    if (auth.user === null || auth.user === undefined) {
        auth.logOut();
        // return;
    }

    const handleImgClick = (event) => {

        setSelectedAvatar(avatarList[event.target.id - 1]);
        props.setAvatar(avatarList[event.target.id - 1]);
        props.closeModal();

    }

    useEffect(() => {


        let list = [];
        for (let i = 1; i < 145; i++) {
            list.push({ id: i, img: './static/avatar/avatar' + num2strLeadingZeros(i, 3) + ".jpg" })
        }
        setAvatarList(list);
    }, []);

    return (
        <>

            <div className="d-flex justify-content-center p-12 m-auto">
                <ImageList sx={{ width: "800px", height: "600px" }} cols={8}>
                    {avatarList.map((item) => (
                        <ImageListItem key={item.id}>
                            <div className='img-container'  >
                                <img
                                    srcSet={`${item.img}?w=64&fit=crop&auto=format&dpr=2 2x`}
                                    src={`${item.img}?w=64&fit=crop&auto=format`}
                                    alt={item.id}
                                    id={item.id}
                                    loading="lazy"
                                    onClick={handleImgClick}
                                    style={{ display: "blocks", borderRadius: "10%", boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.4)" }}
                                    className='holder'
                                />

                            </div>
                        </ImageListItem>
                    ))}
                </ImageList>
            </div>
        </>
    );
}