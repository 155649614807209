import React, { useState } from 'react';
// import axios from 'axios';
// import Button from 'react-bootstrap/Button';
import { useAuth } from "../../hooks/AuthProvider";
import ReCAPTCHA from "react-google-recaptcha";
import './login.css'

/* Tham khao

https://dev.to/miracool/how-to-manage-user-authentication-with-react-js-3ic5 

*/

const LoginForm = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [needCaptcha, setNeedCaptcha] = useState(false);
    const [ckey, setCKey] = useState("");
    const [error, setError] = useState('');


    const grecaptchaObject = window.grecaptcha // You must provide access to the google grecaptcha object.

    const auth = useAuth();

    // console.debug('auth', auth);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (!(username !== "" && password !== "")) {
                alert("pleae provide a valid input");
                return;
            }

            let res = await auth.loginAction({ user_name: username, password, needCaptcha, ckey });

            console.log("RES:=", res);
            if (!(res['needCaptcha'] === null || res['needCaptcha'] === undefined)) {
                setNeedCaptcha(true);
                grecaptchaObject.reset();
            }



            // const response = await axios.post('/dologin', { user_name: username, password });
            // console.log(response.data);

            // if (response.data.usr_name === undefined || response.data.usr_name === null) {
            //     setError('Invalid username or password');
            // } else {
            //     localStorage.setItem('usrdata', response.data);
            // }
            // Redirect or navigate to a protected route
        } catch (err) {
            setNeedCaptcha(true);
            setError('Invalid username or password');
        }
    };


    function onChangeCaptcha(value) {
        setCKey(value);
    }

    return (
        <>
            <div className="Auth-form-container">

                <form className="Auth-form" onSubmit={handleSubmit}>
                    <div className="Auth-form-content">
                        <div className="leftbox">
                        </div>
                        <div className="rightbox">
                            <h3 className="Auth-form-title">Sign In</h3>
                            <div className="form-group mt-3">
                                <label>User name</label>
                                <input
                                    type="text"
                                    id="username"
                                    value={username}
                                    className="form-control mt-1 "
                                    onChange={(e) => setUsername(e.target.value)}
                                    placeholder="Enter email or username"
                                />
                            </div>
                            <div className="form-group mt-3">
                                <label>Password</label>
                                <input
                                    type="password"
                                    className="form-control mt-1"
                                    id="password"
                                    placeholder="Enter password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>

                            {needCaptcha && <div className="form-group mt-3">
                                <ReCAPTCHA
                                    sitekey="6LdijdQSAAAAAPatYPXoMohy98yDVnEZN0lczAVM"

                                    onChange={onChangeCaptcha}
                                />
                            </div>}
                            <div className="d-grid gap-2 mt-3">
                                <button type="submit" className="btn btn-primary">
                                    Login
                                </button>
                            </div>
                            <p className="forgot-password text-right mt-2">
                                Forgot <a href="/renewpass">password?</a>
                            </p>
                            {error && <p>{error}</p>}
                        </div>
                    </div>
                </form>
            </div>

        </>
    );
};

export default LoginForm;
