import React, { useContext, createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { call_api_key } from '../global';

const AuthContext = createContext();

const getUserData = () => {
    try {
        return JSON.parse(secureLocalStorage.getItem('data' + 1));
    } catch (e) {
        return null;
    }
}

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(getUserData());
    const [sysMenu, setSysMenu] = useState([]);
    const [token, setToken] = useState(secureLocalStorage.getItem("data" + 2) || "");
    const navigate = useNavigate();

    const buildMenu = (data) => {
        let menu = [];
        // Menu type =1
        data.forEach(m => {
            if (!m.parent_id) {
                let sub_m = [];
                data.forEach(sm => {
                    if (sm.parent_id === m.menu_id) {
                        sub_m.push(sm);
                    }
                })
                m['sub_menu'] = sub_m;
                menu.push(m);

            }
        });
        // console.debug("Menus:", menu);
        setSysMenu(menu);
        // let usr = { ...user };
        // usr['sysmenu'] = menu;
        // setUser(usr);
        return menu;
    }

    const loginAction = async (data) => {
        try {
            if (!data.needCaptcha) {
                delete data['ckey'];
            }
            const response = await fetch("/api/dologin", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "auth-key": call_api_key,
                },
                body: JSON.stringify(data),
            });
            const res = await response.json();

            //console.debug("Res from API:", res);

            // if (response.data.usr_name === undefined || response.data.usr_name === null) {
            //     setError('Invalid username or password');
            // } else {
            //     localStorage.setItem('usrdata', response.data);
            // }

            if (res.token === undefined || res.token === null) {
                // throw new Error(res.message);
                alert('Login failed!');
                return res;
            } else {

                // Get tailorshop

                fetch("/api/factorymenus", {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "auth-key": call_api_key,
                        "token": res.token
                    },
                    //body: JSON.stringify(data),
                })
                    .then((response) => response.json())
                    //.then((jsonData) => addId(jsonData, "client_id"))
                    .then((rowData) => {
                        if (rowData["result"]) { logOut(); }

                        // console.debug(rowData);
                        let menu = buildMenu(rowData);

                        res['sysMenu'] = menu;

                        setUser(res);
                        // console.debug("Res", res);
                        setToken(res.token);
                        secureLocalStorage.setItem("data" + 2, res.token);
                        secureLocalStorage.setItem("data" + 1, JSON.stringify(res));
                        navigate("/home");
                        window.location.reload();
                        console.log("Login success");

                    })
                    .catch((error) => console.log(error));


                return true;
            }
        } catch (err) {
            console.error(err);
            return { result: "err" };
        }
    };

    const logOut = () => {
        setUser(null);
        setToken("");
        secureLocalStorage.removeItem("data" + 2);
        secureLocalStorage.removeItem("data" + 1);
        navigate("/login");
    };

    return (
        <AuthContext.Provider value={{ token, user, sysMenu, loginAction, logOut }}>
            {children}
        </AuthContext.Provider>
    );

};

export default AuthProvider;

export const useAuth = () => {
    return useContext(AuthContext);
};
