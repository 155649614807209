import React, { useState, useEffect } from 'react';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Tabs from '@mui/material/Tabs';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { DataGrid } from '@mui/x-data-grid';

import { DoModal, ModalContent } from "../../../utils/Modal";
import { Height, Margin, Padding } from '@mui/icons-material';


import { useAuth } from "../../../hooks/AuthProvider";
import { getHeaders } from '../../../utils/apis';
import TailorShopList from './TailorShopList';


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function TailorShopMan() {
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const [tailorShopData, setTailorShop] = useState({});

    const [tabValue, setTabValue] = useState(0);

    const [userData, setUserData] = useState({});

    const onSubmit = data => console.log(data);

    const [isOpen, setIsopen] = useState(false);
    const showModal = () => setIsopen((prev) => !prev);

    const [gridUserData, setGridUserData] = useState([]);

    const userColumns = [
        { field: 'usr_id', headerName: 'ID', width: 90 },
        {
            field: 'usr_name', headerName: 'User Name',
            width: 350,
        },
        {
            field: 'full_name',
            headerName: 'Full Name',
            width: 350,
        },
        {
            field: 'first_name',
            headerName: 'First Name',
            width: 250,
        },
        {
            field: 'last_name',
            headerName: 'Last Name',
            width: 350,
        },
        {
            field: 'gender',
            headerName: 'Gender',
            width: 250,
        },
        {
            field: 'email',
            headerName: 'Email',
            width: 350,
        },
        {
            field: 'state',
            headerName: 'State',
            width: 250,
        },
        {
            field: 'usr_type',
            headerName: 'User Type',
            width: 250,
        },
        {
            field: 'created_date',
            headerName: 'Created Date',
            width: 250,
        },
        {
            field: 'updated_date',
            headerName: 'Updated Date',
            width: 250,
        },
        {
            field: 'country',
            headerName: 'Country',
            width: 250,
        },
        {
            field: 'telephone',
            headerName: 'Telephone',
            width: 250,
        },

        {
            field: 'notes',
            headerName: 'Notes',
            width: 250,
        },


    ];

    const childRef = useRef();

    const auth = useAuth();

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const loadTailorShopUser = (tailorshop_id) => {
        fetch("/api/tailorshopusers/" + tailorshop_id, {
            method: "GET",
            headers: getHeaders(auth),
            //body: JSON.stringify(data),
        })
            .then((response) => response.json())
            //.then((jsonData) => addId(jsonData, "client_id"))
            .then((rowData) => {
                if (rowData["result"]) { auth.logOut(); return; }

                setGridUserData(rowData);

            })
            .catch((error) => console.log(error));
        //fetchData();
    }


    useEffect(() => {

        if (!auth.user) {
            auth.logOut();
            return;
        }

        // fetch("/api/roles", {
        //     method: "GET",
        //     headers: getHeaders(auth),
        //     //body: JSON.stringify(data),
        // })
        //     .then((response) => response.json())
        //     //.then((jsonData) => addId(jsonData, "client_id"))
        //     .then((rowData) => {
        //         if (rowData["result"]) { auth.logOut(); return;}

        //         setSysRolesData(rowData);
        //         setRoleRight(rowData);
        //     })
        //     .catch((error) => console.log(error));
        // //fetchData();

    }, []);

    const loadUserRoles = (usr_id) => {
        fetch("/api/userroles/" + usr_id, {
            method: "GET",
            headers: getHeaders(auth),
            //body: JSON.stringify(data),
        })
            .then((response) => response.json())
            //.then((jsonData) => addId(jsonData, "client_id"))
            .then((rowData) => {
                if (rowData["result"]) { auth.logOut(); return; }

                //                setRoleLeft(rowData);

                // let right = [];
                // sysrolesData.forEach((e) => {
                //     let found = rowData.find((element) => element.role_id === e.role_id);
                //     if (!found) {
                //         right.push(e);
                //     }
                // });

                // setRoleRight(right);
            })
            .catch((error) => console.log(error));
        //fetchData();
    }

    const selectTailorShop = (data) => {
        setTailorShop({ ...data });
        setValue("tailorshop_id", data['tailorshop_id']);
        setValue("name", data['name']);
        setValue("short_name", data['short_name']);
        setValue("address", data['address']);
        setValue("country", data['country']);
        setValue("contact", data['contact']);
        setValue("email", data['email']);
        setValue("tel", data['tel']);
        setValue("notes", data['notes']);

        childRef.current?.callReloadData(data['tailorshop_id']);

        //setUserData(data);
        // loadUserRoles(data['usr_id']);
        // loadUserGroups(data['usr_id']);
        // loadUserPerm(data['usr_id']);
        loadTailorShopUser(data['tailorshop_id']);
    }

    return (
        <div style={{ margin: "30px" }}>
            {<TailorShopList selectTailorShop={selectTailorShop} />}
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tabValue} onChange={handleChange} aria-label="User Tabs">
                        <Tab label="Tailor Shop" {...a11yProps(0)} style={{ textTransform: 'none', }} />
                        <Tab label="Orders" {...a11yProps(1)} style={{ textTransform: 'none', }} />
                        <Tab label="Users" {...a11yProps(2)} style={{ textTransform: 'none', }} />
                    </Tabs>
                </Box>
                <CustomTabPanel value={tabValue} index={0}>
                    <h3>Tailor shop detail</h3>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Container className="p-3 ">
                            <Row style={{ width: "100%" }}>
                                <Col >
                                    <div className="mb-3">
                                        <label>Tailor Shop ID:</label>&nbsp;<br />
                                        <input type="text" placeholder="usr_id" {...register("tailorshop_id", { required: true })} disabled={true} />
                                    </div>

                                    <div className="mb-3">
                                        <label>Tailorshop Name:</label>&nbsp;<br />
                                        <input type="text" placeholder="name" {...register("name", { required: true })} />
                                    </div>
                                    <div className="mb-3">
                                        <label>Short Name:</label>&nbsp;<br />
                                        <input type="text" placeholder="short_name" {...register("short_name", { required: true })} />
                                    </div>
                                    <div className="mb-3">
                                        <label>Address:</label>&nbsp;<br />
                                        <input type="text" placeholder="address" {...register("address", { required: true })} />
                                    </div>
                                    <div className="mb-3">
                                        <label>Country:</label>&nbsp;<br />
                                        <input type="text" placeholder="country" {...register("country", {})} />
                                    </div>
                                    <div className="mb-3">
                                        <label>Contact:</label>&nbsp;<br />
                                        <input type="text" placeholder="contact" {...register("contact", { required: true })} />
                                    </div>

                                    <div className="mb-3">
                                        <label>Email:</label>&nbsp;<br />
                                        <input type="email" placeholder="email" {...register("email", { required: true })} />
                                    </div>
                                    <div className="mb-3">
                                        <label>Telephonet:</label>&nbsp;<br />
                                        <input type="text" placeholder="tel" {...register("tel", {})} />
                                    </div>
                                    <div className="mb-3">
                                        <label>Notes:</label>&nbsp;<br />
                                        <input type="text" placeholder="notes" {...register("notes", {})} />
                                    </div>
                                </Col>

                            </Row>
                        </Container>
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </form>
                </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={1} >
                    <h3>Orders</h3>

                    <button type="button" className="btn btn-primary" >Save</button>
                </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={2}>
                    <h3>Users List</h3>
                    <DataGrid
                        rows={gridUserData}
                        columns={userColumns}
                        checkboxSelection={false}
                        disableMultipleRowSelection={true}
                        //columnGroupingModel={columnGroupingModel}
                        density='compact'
                        // onRowClick={handleRowClick}
                        pageSizeOptions={[5, 10, 25]}
                        initialState={{
                            pagination: {
                                paginationModel: { pageSize: 5, page: 0 },
                            },
                        }}
                    />
                    <button type="button" className="btn btn-primary" >Save</button>
                </CustomTabPanel>


            </Box>
        </div >
    );
}




