import React, { useState, useEffect } from 'react';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Tabs from '@mui/material/Tabs';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { DataGrid } from '@mui/x-data-grid';

import { DoModal, ModalContent } from "../../utils/Modal";
import { Height, Margin, Padding } from '@mui/icons-material';
import UsersMan from './UsersMan';
import './UsersAndRoles.css';
import AvatarSelect from '../../components/avatarSelect';

import { useAuth } from "../../hooks/AuthProvider";
import { getHeaders } from '../../utils/apis';
import AddModUser from './AddModUser';


function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

const permColumns = [
    { field: 'permission_id', headerName: 'ID', width: 90 },
    {
        field: 'name', headerName: 'Name',
        width: 350,
    },
    {
        field: 'notes',
        headerName: 'Notes',
        width: 350,
    },
    {
        field: 'permission_code',
        headerName: 'Code',
        width: 250,
    },
];

const columnGroupingModel = [
    {
        groupId: 'Internal',
        description: '',
        children: [{ field: 'id' }],
    },
    {
        groupId: 'Basic info',
        children: [
            {
                groupId: 'Full name',
                children: [{ field: 'last_name' }, { field: 'first_name' }],
            },
            { field: 'age' },
        ],
    },
];

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function UsersAndRoles() {
    // const { register, handleSubmit, setValue, formState: { errors } } = useForm();

    const [userData, setUserData] = useState({});

    // Roles
    const [sysrolesData, setSysRolesData] = useState([]);

    const [roleChecked, setRoleChecked] = useState([]);
    const [roleLeft, setRoleLeft] = useState([]);
    const [roleRight, setRoleRight] = useState([]);

    const leftRoleChecked = intersection(roleChecked, roleLeft);
    const rightRoleChecked = intersection(roleChecked, roleRight);

    // Group
    const [sysgroupData, setSysGroupData] = useState([]);
    const [groupChecked, setGroupChecked] = useState([]);
    const [groupLeft, setGroupLeft] = useState([]);
    const [groupRight, setGroupRight] = useState([]);

    const leftGroupChecked = intersection(groupChecked, groupLeft);
    const rightGroupChecked = intersection(groupChecked, groupRight);

    //Permission
    const [gridPermData, setGridPermData] = useState([]);

    const onSubmit = data => console.log(data);

    const [isOpen, setIsopen] = useState(false);
    const showModal = () => setIsopen((prev) => !prev);

    const [tabValue, setTabValue] = useState(0);

    const childRef = useRef();

    const auth = useAuth();

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleRolesToggle = (value) => () => {
        const currentIndex = roleChecked.indexOf(value);
        const newChecked = [...roleChecked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setRoleChecked(newChecked);
    };

    const handleRolesAllRight = () => {
        setRoleRight(roleRight.concat(roleLeft));
        setRoleLeft([]);
    };

    const handleRolesCheckedRight = () => {
        setRoleRight(roleRight.concat(leftRoleChecked));
        setRoleLeft(not(roleLeft, leftRoleChecked));
        setRoleChecked(not(roleChecked, leftRoleChecked));
    };

    const handleRolesCheckedLeft = () => {
        setRoleLeft(roleLeft.concat(rightRoleChecked));
        setRoleRight(not(roleRight, rightRoleChecked));
        setRoleChecked(not(roleChecked, rightRoleChecked));
    };

    const handleRolesAllLeft = () => {
        setRoleLeft(roleLeft.concat(roleRight));
        setRoleRight([]);
    };

    //---------
    const handleGroupToggle = (value) => () => {
        const currentIndex = groupChecked.indexOf(value);
        const newChecked = [...groupChecked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setGroupChecked(newChecked);
    };

    const handleGroupAllRight = () => {
        setGroupRight(groupRight.concat(groupLeft));
        setGroupLeft([]);
    };

    const handleGroupCheckedRight = () => {
        setGroupRight(groupRight.concat(leftGroupChecked));
        setGroupLeft(not(groupLeft, leftGroupChecked));
        setGroupChecked(not(groupChecked, leftGroupChecked));
    };

    const handleGroupCheckedLeft = () => {
        setGroupLeft(groupLeft.concat(rightGroupChecked));
        setGroupRight(not(groupRight, rightGroupChecked));
        setGroupChecked(not(groupChecked, rightGroupChecked));
    };

    const handleGroupAllLeft = () => {
        setGroupLeft(groupLeft.concat(groupRight));
        setGroupRight([]);
    };

    const customRolesList = (items) => (
        <Paper sx={{ width: 400, height: 430, overflow: 'auto' }}>

            <List dense component="div" role="list">
                {items.map((e) => {
                    const labelId = `transfer-list-item-${e.role_id}-label`;

                    return (
                        <ListItemButton
                            key={e.role_id}
                            role="listitem"
                            onClick={handleRolesToggle(e)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={roleChecked.indexOf(e) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={`${e.role_name}`} />
                        </ListItemButton>
                    );
                })}
            </List>
        </Paper>
    );

    const customGroupList = (items) => (
        <Paper sx={{ width: 400, height: 430, overflow: 'auto' }}>

            <List dense component="div" role="list">
                {items.map((e) => {
                    const labelId = `transfer-list-item-${e.group_id}-label`;

                    return (
                        <ListItemButton
                            key={e.group_id}
                            role="listitem"
                            onClick={handleGroupToggle(e)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={groupChecked.indexOf(e) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={`${e.group_name}`} />
                        </ListItemButton>
                    );
                })}
            </List>
        </Paper>
    );

    const handleSaveUserRoles = () => {
        if (!userData['usr_id']) {
            return
        }

        let roles = roleLeft.map(a => a.role_id);

        console.log("Roles:=", roles);
        fetch("/api/userroles/", {
            method: "POST",
            headers: getHeaders(auth),
            body: JSON.stringify({
                usr_id: userData['usr_id'],
                roles: roles
            }),
        })
            .then((response) => response.json())
            //.then((jsonData) => addId(jsonData, "client_id"))
            .then((rowData) => {
                if (rowData["result"]) { auth.logOut(); return; }
                alert("Save user's roles is successful");
            })
            .catch((error) => console.log(error));
        //fetchData();
    }

    const handleSaveUserGroup = () => {
        if (!userData['usr_id']) {
            return
        }

        let groups = groupLeft.map(a => a.group_id);

        console.log("Group:=", groups);
        fetch("/api/usergroups/", {
            method: "POST",
            headers: getHeaders(auth),
            body: JSON.stringify({
                usr_id: userData['usr_id'],
                groups: groups
            }),
        })
            .then((response) => response.json())
            //.then((jsonData) => addId(jsonData, "client_id"))
            .then((rowData) => {
                if (rowData["result"]) { auth.logOut(); return; }
                alert("Save user's group is successful");
            })
            .catch((error) => console.log(error));
        //fetchData();
    }

    const setAvatar = (avatar) => {

        fetch(avatar['img'])
            .then(response => response.blob())
            .then(blob => {
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                return new Promise((res) => {
                    reader.onloadend = () => {
                        res(reader.result);
                    }
                })
            }).then(data => {
                console.log("IMG=", data);

                userData['avatar'] = data;
                setUserData({ ...userData });
            })


    }

    useEffect(() => {

        if (!auth.user) {
            auth.logOut();
            return;
        }


        fetch("/api/roles", {
            method: "GET",
            headers: getHeaders(auth),
            //body: JSON.stringify(data),
        })
            .then((response) => response.json())
            //.then((jsonData) => addId(jsonData, "client_id"))
            .then((rowData) => {
                if (rowData["result"]) { auth.logOut(); return; }

                setSysRolesData(rowData);
                setRoleRight(rowData);
            })
            .catch((error) => console.log(error));
        //fetchData();

        fetch("/api/groups", {
            method: "GET",
            headers: getHeaders(auth),
            //body: JSON.stringify(data),
        })
            .then((response) => response.json())
            //.then((jsonData) => addId(jsonData, "client_id"))
            .then((rowData) => {
                if (rowData["result"]) { auth.logOut(); return; }

                setSysGroupData(rowData);
                setGroupRight(rowData);
            })
            .catch((error) => console.log(error));
        //fetchData();
    }, []);

    const loadUserRoles = (usr_id) => {
        fetch("/api/userroles/" + usr_id, {
            method: "GET",
            headers: getHeaders(auth),
            //body: JSON.stringify(data),
        })
            .then((response) => response.json())
            //.then((jsonData) => addId(jsonData, "client_id"))
            .then((rowData) => {
                if (rowData["result"]) { auth.logOut(); return; }

                setRoleLeft(rowData);

                let right = [];
                sysrolesData.forEach((e) => {
                    let found = rowData.find((element) => element.role_id === e.role_id);
                    if (!found) {
                        right.push(e);
                    }
                });

                setRoleRight(right);
            })
            .catch((error) => console.log(error));
        //fetchData();
    }

    const loadUserGroups = (usr_id, tailorshop_id) => {

        fetch("/api/usergroups/" + usr_id, {
            method: "GET",
            headers: getHeaders(auth),
            //body: JSON.stringify(data),
        })
            .then((response) => response.json())
            //.then((jsonData) => addId(jsonData, "client_id"))
            .then((rowData) => {
                if (rowData["result"]) { auth.logOut(); return; }

                setGroupLeft(rowData);

                let right = [];
                sysgroupData.forEach((e) => {
                    let found = rowData.find((element) => element.group_id === e.group_id);
                    if (!found) {
                        if (e.owner === tailorshop_id || e.owner === 0) {
                            right.push(e);
                        }
                    }
                });

                setGroupRight(right);
            })
            .catch((error) => console.log(error));
        //fetchData();
    }

    const loadUserPerm = (usr_id) => {
        fetch("/api/userperm/" + usr_id, {
            method: "GET",
            headers: getHeaders(auth),
            //body: JSON.stringify(data),
        })
            .then((response) => response.json())
            //.then((jsonData) => addId(jsonData, "client_id"))
            .then((rowData) => {
                if (rowData["result"]) { auth.logOut(); return; }

                setGridPermData(rowData);

            })
            .catch((error) => console.log(error));
        //fetchData();
    }

    const selectClient = (data, next) => {
        // setClientData({ ...data });
        // // setValue("usr_id", data['usr_id']);
        // // setValue("usr_name", data['usr_name']);
        // // setValue("first_name", data['first_name']);
        // // setValue("last_name", data['last_name']);
        // // setValue("full_name", data['full_name']);
        // // setValue("email", data['email']);
        // // setValue("telephone", data['telephone']);
        // // setValue("gender", data['gender']);
        // // setValue("country", data['country']);
        // // setValue("created_date", data['created_date']);
        // // setValue("updated_date", data['updated_date']);
        // // setValue("notes", data['notes']);
        // // setValue("state", data['state']);

        //   console.log('User:', data);

        setUserData({ ...data });
        childRef.current?.callReloadData(data['client_id']);


        loadUserRoles(data['usr_id']);
        loadUserGroups(data['usr_id'], data['tailorshop_id']);
        loadUserPerm(data['usr_id']);

    }

    return (
        <div style={{ margin: "30px" }}>
            {<UsersMan selectClient={selectClient} />}
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tabValue} onChange={handleChange} aria-label="User Tabs">
                        <Tab label="Profile" {...a11yProps(0)} style={{ textTransform: 'none', }} />
                        <Tab label="Groups" {...a11yProps(1)} style={{ textTransform: 'none', }} />
                        <Tab label="Roles" {...a11yProps(2)} style={{ textTransform: 'none', }} />
                        <Tab label="Permissions" {...a11yProps(3)} style={{ textTransform: 'none', }} />
                    </Tabs>
                </Box>
                <CustomTabPanel value={tabValue} index={0}>
                    <h3> User Profile</h3>
                    <AddModUser userData={userData} />
                </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={1} >
                    <h3>Groups</h3>
                    <Grid container spacing={2} justifyContent="center" alignItems="center">

                        <Grid item>Groups for this user{customGroupList(groupLeft)}</Grid>
                        <Grid item>
                            <Grid container direction="column" alignItems="center">
                                <Button
                                    sx={{ my: 0.5 }}
                                    variant="outlined"
                                    size="small"
                                    onClick={handleGroupAllRight}
                                    disabled={groupLeft.length === 0}
                                    aria-label="move all right"
                                >
                                    ≫
                                </Button>
                                <Button
                                    sx={{ my: 0.5 }}
                                    variant="outlined"
                                    size="small"
                                    onClick={handleGroupCheckedRight}
                                    disabled={leftGroupChecked.length === 0}
                                    aria-label="move selected right"
                                >
                                    &gt;
                                </Button>
                                <Button
                                    sx={{ my: 0.5 }}
                                    variant="outlined"
                                    size="small"
                                    onClick={handleGroupCheckedLeft}
                                    disabled={rightGroupChecked.length === 0}
                                    aria-label="move selected left"
                                >
                                    &lt;
                                </Button>
                                <Button
                                    sx={{ my: 0.5 }}
                                    variant="outlined"
                                    size="small"
                                    onClick={handleGroupAllLeft}
                                    disabled={groupRight.length === 0}
                                    aria-label="move all left"
                                >
                                    ≪
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item>Group available{customGroupList(groupRight)}</Grid>

                    </Grid>
                    <button type="button" className="btn btn-primary" onClick={handleSaveUserGroup}>Save</button>
                </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={2}>
                    <h3>Roles</h3>
                    <Grid container spacing={2} justifyContent="center" alignItems="center">

                        <Grid item>Roles for this user{customRolesList(roleLeft)}</Grid>
                        <Grid item>
                            <Grid container direction="column" alignItems="center">
                                <Button
                                    sx={{ my: 0.5 }}
                                    variant="outlined"
                                    size="small"
                                    onClick={handleRolesAllRight}
                                    disabled={roleLeft.length === 0}
                                    aria-label="move all right"
                                >
                                    ≫
                                </Button>
                                <Button
                                    sx={{ my: 0.5 }}
                                    variant="outlined"
                                    size="small"
                                    onClick={handleRolesCheckedRight}
                                    disabled={leftRoleChecked.length === 0}
                                    aria-label="move selected right"
                                >
                                    &gt;
                                </Button>
                                <Button
                                    sx={{ my: 0.5 }}
                                    variant="outlined"
                                    size="small"
                                    onClick={handleRolesCheckedLeft}
                                    disabled={rightRoleChecked.length === 0}
                                    aria-label="move selected left"
                                >
                                    &lt;
                                </Button>
                                <Button
                                    sx={{ my: 0.5 }}
                                    variant="outlined"
                                    size="small"
                                    onClick={handleRolesAllLeft}
                                    disabled={roleRight.length === 0}
                                    aria-label="move all left"
                                >
                                    ≪
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item>Roles available{customRolesList(roleRight)}</Grid>

                    </Grid>
                    <button type="button" className="btn btn-primary" onClick={handleSaveUserRoles}>Save</button>
                </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={3}>
                    <h3>Permissions</h3>

                    <DataGrid
                        rows={gridPermData}
                        columns={permColumns}
                        checkboxSelection={false}
                        disableMultipleRowSelection={true}
                        //columnGroupingModel={columnGroupingModel}
                        density='compact'
                        // onRowClick={handleRowClick}
                        pageSizeOptions={[5, 10, 25]}
                        initialState={{
                            pagination: {
                                paginationModel: { pageSize: 5, page: 0 },
                            },
                        }}
                    />
                </CustomTabPanel>

            </Box>




        </div >
    );
}




