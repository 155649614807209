import React, { forwardRef, useState, useEffect, useImperativeHandle } from 'react';
import { useForm } from 'react-hook-form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import { useAuth } from "../../../hooks/AuthProvider";
import { getHeaders } from '../../../utils/apis';
import './BodyMeasurement.css';
import OldShirtMeasurement from './OldShirtMeasurement';


const BodyMeasurement = forwardRef(function BodyMeasurement(props, submitRef) {

    const { register, handleSubmit, formState: { errors }, reset, getValues } = useForm();
    const [measureType, setMeasureType] = useState("1");
    const [typeIdx, setTypeIdx] = useState(0);
    const [lastorder, setLastOrder] = useState([]);


    const guides = [
        { id: "neck", img_src: "measure-collar.png" },
        { id: "chest", img_src: "measure-chest.png" },
        { id: "waist", img_src: "measure-waist.png" },
        { id: "hip", img_src: "measure-seat.png" },
        { id: "shoulders", img_src: "measure-shoulder.png" },
        { id: "arm_length", img_src: "measure-sleeve.png" },
        { id: "upper_arm", img_src: "measure-upper_arm.png" },
        { id: "wrist", img_src: "measure-wrist.png" },
        { id: "back_length", img_src: "measure-back.png" },
    ]

    const auth = useAuth();
    if (auth.user === null || auth.user === undefined) {
        auth.logOut();
        // return;
    }

    const curData = props.orderData;


    const onSelectChange = (event) => {
        // event.preventDefault();
        // event.persist();
        // event.stopPropagation();
        // setMeasureType(eventKey);
        console.log(event) // selected event will trigger
    }

    useEffect(() => {

        if (!auth.user) {
            auth.logOut();
            return;
        }


        if (curData?.client_id === null || curData?.client_id === undefined) {
            return
        }
        fetch("/api/measurements/" + curData.client_id, {
            method: "GET",
            headers: getHeaders(auth),
            //body: JSON.stringify(data),
        })
            .then((response) => response.json())
            //.then((jsonData) => addId(jsonData, "client_id"))
            .then((rowData) => {
                console.debug("curData.client_id=", curData.client_id);
                console.debug("DEBUG: fetch =", rowData);
                if (rowData["result"]) { auth.logOut(); return }

                curData['measurements'] = rowData[0];
                props.measurementUpdate(curData, false);

                reset(rowData[0]);
            })
            .catch((error) => console.log(error));
        //fetchData();


        fetch("/api/lastorder/" + curData.client_id, {
            method: "GET",
            headers: getHeaders(auth),
            //body: JSON.stringify(data),
        })
            .then((response) => response.json())
            //.then((jsonData) => addId(jsonData, "client_id"))
            .then((rowData) => {
                console.debug("curData.client_id=", curData.client_id);
                console.debug("DEBUG: fetch =", rowData);
                if (rowData["result"]) { auth.logOut(); return }

                setLastOrder(rowData);
            })
            .catch((error) => console.log(error));
        //fetchData();


    }, []);

    const measureInputFocus = (event) => {

        const index = guides.findIndex(item => item.id === event.target.id);
        //   alert(index);
        if (index === -1) {
            index = 0;
        }
        setTypeIdx(index);
    }

    useImperativeHandle(submitRef, () => {

        return {
            getAlert() {
                alert("getAlert from Child");
            },
            doSubmit() {
                onSubmit(getValues());
            }
        };
    }, []);

    const onSubmit = (data) => {



        if (auth.user === null || auth.user === undefined) {
            auth.logOut();
            return;
        }

        // console.debug("DEBUG data:", data);
        // console.debug("DEBUG getValues():", getValues());

        alert('do Submit');

        data['client_id'] = curData.client_id;
        delete data['measure_id'];

        if (data['remark'] == null) data['remark'] = '';
        if (data['body_height'] == null) data['body_height'] = 0;
        if (data['body_weight'] == null) data['body_weight'] = 0;


        data['measure_type'] = measureType;

        fetch("/api/entity/measurements", {
            method: "POST",
            headers: getHeaders(auth),
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((rowData) => {
                if (rowData["result"]) {
                    if (rowData["result"] === "timeout") {
                        auth.logOut();
                    } else {
                        alert("Error when query DB");
                    }
                } else {
                    console.log("Add new return:", rowData[0]);
                    data['measurement_id'] = rowData[0]['measurement_id'];
                    curData['measurements'] = data;
                    // alert("Add measurement successful");
                    props.measurementUpdate(curData, true);
                    reset();
                }
                // console.log(rowData);
            })
            .catch((error) => { console.log(error); alert("Error call API"); });

        //console.log(data);

    }



    return (
        <>
            <div className='bodyMeasure'>
                <form onSubmit={handleSubmit(onSubmit)} ref={submitRef}>
                    <Container className="p-3 ">
                        <Row style={{ width: "100%" }}>
                            <div>
                                Client name : {curData.first_name} {curData.last_name_name}
                            </div>
                            <div>
                                Email : {curData.email}
                            </div>
                            {/* <Dropdown style={{ width: "250px" }} onSelect={onTypeSelect} >
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    Select measurement type
                                </Dropdown.Toggle>

                                <Dropdown.Menu >
                                    <Dropdown.Item href="#/action-1" eventKey="1">Body Measurement</Dropdown.Item>
                                    <Dropdown.Item href="#/action-2" eventKey="2">Measurement from the last order</Dropdown.Item>
                                    <Dropdown.Item href="#/action-3" eventKey="3">Shirt Measurement</Dropdown.Item>

                                </Dropdown.Menu>
                            </Dropdown> */}

                            <select className="form-select" aria-label="Select measurement type" style={{ width: "250px" }} onChange={onSelectChange}>
                                <option selected value="1">Body Measurement</option>
                                <option value="2">Measurement from the last order</option>
                                <option value="3">Shirt Measurement</option>
                            </select>

                            <select onChange={onSelectChange}>
                                <option value="Orange">Orange</option>
                                <option value="Radish">Radish</option>
                                <option value="Cherry">Cherry</option>
                            </select>

                        </Row>
                        {(measureType === "1" || measureType === "2") && <>
                            {measureType === "2" &&
                                <Row style={{ width: "100%" }}>
                                    Last orders:
                                    <select {...register("lastorder")}>
                                        <option value="12">12 - Fitted</option>
                                        <option value="33">33 - Loose</option>
                                        <option value="44">44 - Normal</option>
                                    </select>
                                </Row>
                            }
                            <Row style={{ width: "100%" }}>
                                <Col md="6">
                                    {/* <div className="mb-3">
                                    <label>Measure ID:</label><br />
                                    <input type="text" disabled={true} />
                                </div> */}

                                    <div className="mb-3">
                                        <label>Neck (cm):</label><br />
                                        <input type="number" placeholder="neck" {...register("neck", { required: true })} /></div>

                                    <div className="mb-3">
                                        <label>Chest (cm):</label><br />
                                        <input type="number" placeholder="chest" {...register("chest", { required: true })} /></div>
                                    <div className="mb-3">
                                        <label>Waist (cm):</label><br />
                                        <input type="number" placeholder="waist" {...register("waist", { required: true })} /></div>
                                    <div className="mb-3">
                                        <label>Hip (cm):</label><br />
                                        <input type="number" placeholder="hip" {...register("hip", { required: true })} /></div>
                                    <div className="mb-3">
                                        <label>Shoulders (cm):</label><br />
                                        <input type="number" placeholder="shoulders" {...register("shoulders", { required: true })} /></div>
                                </Col>
                                <Col>

                                    <div className="mb-3">
                                        <label>Arm Length (cm):</label><br />
                                        <input type="number" placeholder="arm length" {...register("arm_length", { required: true })} /></div>
                                    <div className="mb-3">
                                        <label>Upper Arm (cm):</label><br />
                                        <input type="number" placeholder="upper arm" {...register("upper_arm", { required: true })} /></div>
                                    <div className="mb-3">
                                        <label>Wrist (cm):</label><br />
                                        <input type="number" placeholder="wrist" {...register("wrist", { required: true })} /></div>
                                    <div className="mb-3">
                                        <label>Back Length (cm):</label><br />
                                        <input type="number" placeholder="casual back" {...register("back_length", { required: true })} /></div>
                                    {/* <div className="mb-3">
                                    <label>More Notes:</label><br />
                                    <input type="text" placeholder="Notes" {...register("notes", { required: false })} /></div> */}


                                </Col>
                            </Row>
                            <Row>
                                Option Informations:
                                <div>&nbsp;</div>
                                {/* <div className="mb-3">
                                    <label>Remark for measurement:</label><br />
                                    <input type="text" placeholder="remark" {...register("remark", { required: true })} /></div> */}
                                <Col>
                                    <label>Body Height (cm):</label><br />
                                    <input type="number" placeholder="body height" {...register("body_height", { required: false })} /></Col>
                                <Col>
                                    <label>Body Weight (kg):</label><br />
                                    <input type="number" placeholder="body weight" {...register("body_weight", { required: false })} /></Col>
                                <Col>
                                    <label>Shoe size:</label><br />
                                    <input type="number" placeholder="shoes size" {...register("shoe_size", { required: false })} /></Col>

                            </Row>
                        </>
                        }

                        {measureType === "3" && <>
                            <Row style={{ width: "100%" }}>
                                <Col>
                                    <div className="mb-2">
                                        <label>Neck (cm):</label><br />
                                        <input type="number" id="neck" placeholder="neck" {...register("neck", { required: true })} onFocus={measureInputFocus} /></div>

                                    <div className="mb-2">
                                        <label>Chest (cm):</label><br />
                                        <input type="number" id="chest" placeholder="chest" {...register("chest", { required: true })} onFocus={measureInputFocus} /></div>


                                    <div className="mb-2">
                                        <label>Waist (cm):</label><br />
                                        <input type="number" id="waist" placeholder="waist" {...register("waist", { required: true })} onFocus={measureInputFocus} /></div>

                                    <div className="mb-2">
                                        <label>Hip (cm):</label><br />
                                        <input type="number" id="hip" placeholder="hip" {...register("hip", { required: true })} onFocus={measureInputFocus} /></div>
                                    <div className="mb-2">
                                        <label>Shoulders (cm):</label><br />
                                        <input type="number" id="shoulders" placeholder="shoulders" {...register("shoulders", { required: true })} onFocus={measureInputFocus} /></div>
                                    <div className="mb-2">
                                        <label>Arm Length (cm):</label><br />
                                        <input type="number" id="arm_length" placeholder="arm length" {...register("arm_length", { required: true })} onFocus={measureInputFocus} /></div>

                                    <div className="mb-2">
                                        <label>Upper Arm (cm):</label><br />
                                        <input type="number" id="upper_arm" placeholder="upper arm" {...register("upper_arm", { required: true })} onFocus={measureInputFocus} /></div>
                                    <div className="mb-2">
                                        <label>Wrist (cm):</label><br />
                                        <input type="number" id="wrist" placeholder="wrist" {...register("wrist", { required: true })} onFocus={measureInputFocus} /></div>
                                    <div className="mb-2">
                                        <label>Back Length (cm):</label><br />
                                        <input type="number" id="back_length" placeholder="back-length" {...register("back_length", { required: true })} onFocus={measureInputFocus} /></div>
                                </Col>
                                <Col>
                                    <img src={"./images/shirt_measure/" + guides[typeIdx].img_src} className="img-fluid" alt="Fabric" style={{ height: "300px", display: "block", margin: "auto", span: "20px" }} />



                                </Col>


                            </Row>

                            <Row>
                                Option Informations: <div>&nbsp;</div>
                                {/* <div className="mb-3">
                                    <label>Remark for measurement:</label><br />
                                    <input type="text" placeholder="remark" {...register("remark")} /></div> */}
                                <Col>
                                    <label>Body Height (cm):</label><br />
                                    <input type="number" placeholder="body height" {...register("body_height", { required: false })} /></Col>
                                <Col>
                                    <label>Body Weight (kg):</label><br />
                                    <input type="number" placeholder="body weight" {...register("body_weight")} /></Col>
                                <Col>
                                    <label>Shoe size: (Optional)</label><br />
                                    <input type="number" placeholder="shoes size" {...register("shoe_size")} /></Col>

                            </Row>
                        </>
                        }
                    </Container>
                    <button type="submit" className="btn btn-primary" >Submit</button>
                </form>
            </div>
            {/* DEBUG: {JSON.stringify(lastorder)} */}
        </>
    );
});

export default BodyMeasurement;