import React, { useState, useEffect } from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import Rating from '@mui/material/Rating';
import { useAuth } from "../../hooks/AuthProvider";
import { getHeaders } from '../../utils/apis';
import { useForm } from 'react-hook-form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { styled } from '@mui/material';
import './TailorShopReg.css';

export default function TailorShopReg(props) {
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const onSubmit = data => console.log(data);
    //console.log(errors);


    const auth = useAuth();
    if (auth.user === null || auth.user === undefined) {
        auth.logOut();
        // return;
    }

    useEffect(() => {

        if (!auth.user) {
            auth.logOut();
            return;
        }


        fetch("/api/tailorshop/" + auth.user.tailorshop_id, {
            method: "GET",
            headers: getHeaders(auth),
            //body: JSON.stringify(data),
        })
            .then((response) => response.json())
            //.then((jsonData) => addId(jsonData, "client_id"))
            .then((data) => {
                console.debug("data", data);
                if (data["result"]) {
                    auth.logOut();
                } else {

                    setValue("tailorshop_id", data['tailorshop_id']);
                    setValue("name", data['name']);
                    setValue("short_name", data['short_name']);
                    setValue("address", data['address']);
                    setValue("country", data['country']);
                    setValue("contact", data['contact']);
                    setValue("email", data['email']);
                    setValue("tel", data['tel']);
                    setValue("notes", data['notes']);
                    setValue("state", data['state']);
                }
            })
            .catch((error) => console.log(error));
        //fetchData();
    }, []);

    return (
        <div className='container'>
            <form onSubmit={handleSubmit(onSubmit)}>
                <h3>Tailor Shop</h3>

                <Container className="p-3 ">
                    <Row style={{ width: "100%" }}>
                        <Col md="6">
                            <div className="mb-3">
                                <label>Shop/Brand ID:</label><br />
                                <input type="text" placeholder="Shop ID" {...register("tailorshop_id", { required: true })} disabled={true} />
                            </div>
                            <div className="mb-3">
                                <label>Name:</label><br />
                                <input type="text" placeholder="name" {...register("name", { required: true })} />
                            </div>
                            <div className="mb-3">
                                <label>Short name:</label>&nbsp;<br />
                                <input type="text" placeholder="short_name" {...register("short_name", { required: true })} />
                            </div>
                            <div className="mb-3">
                                <label>Address:</label>&nbsp;<br />
                                <input type="text" placeholder="address" {...register("address", {})} />
                            </div>
                            <div className="mb-3">
                                <label>Country:</label>&nbsp;<br />
                                <input type="text" placeholder="country" {...register("country", {})} />
                            </div>
                        </Col>
                        <Col md="6">
                            <div className="mb-3">
                                <label>Contact point:</label>&nbsp;<br />
                                <input type="text" placeholder="contact" {...register("contact", {})} />
                            </div>
                            <div className="mb-3">
                                <label>Email:</label>&nbsp;<br />
                                <input type="email" placeholder="email" {...register("email", {})} />
                            </div>
                            <div className="mb-3">
                                <label>Telephone:</label><br />
                                <input type="text" placeholder="telephone" {...register("tel", {})} />
                            </div>
                            <div className="mb-3">
                                <label>Notes:</label>&nbsp;<br />
                                <input type="text" placeholder="notes" {...register("notes", {})} />
                            </div>
                            <div className="mb-3">
                                <label>Shop state:</label>&nbsp;<br />
                                <select {...register("state")}>
                                    <option value="1">Active</option>
                                    <option value="2">Locked</option>
                                    {/* <option value="3">Wait approved</option> */}
                                    <option value="9">Deleted</option>
                                </select>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <button type="submit" className="btn btn-primary">Submit</button>
            </form>
        </div>
    );
}

