import React, { useState, useEffect } from 'react';

import { useNavigate } from "react-router-dom";

import { DataGrid } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';
import { useAuth } from "../../hooks/AuthProvider";
import { getHeaders } from '../../utils/apis';

const columns = [
    { field: 'role_id', headerName: 'ID', width: 90 },
    {
        field: 'role_name', headerName: 'Role name',
        width: 250,
    },
    {
        field: 'notes',
        headerName: 'Notes',
        width: 250,
    }
];

const columnGroupingModel = [
    {
        groupId: 'Internal',
        description: '',
        children: [{ field: 'id' }],
    },
    {
        groupId: 'Basic info',
        children: [
            {
                groupId: 'Full name',
                children: [{ field: 'last_name' }, { field: 'first_name' }],
            },
            { field: 'age' },
        ],
    },
];

export default function RolesMan(props) {
    const [searchText, setSearchText] = useState("");

    const [gridData, setGridData] = useState([]);

    const auth = useAuth();
    const navigate = useNavigate();

    const changeSearchText = (event) => {
        setSearchText(event.target.value);
    }

    const handleRowClick = (params) => {
        //console.log('Click Row: ', params.row);
        props.selectRole(params.row, false);
    };

    const handleSearchBtn = (event) => {
        if (searchText == null || searchText == undefined || searchText === "") {
            setSearchText(null);
        }

        fetch("/api/searchroles/", {
            method: "POST",
            headers: getHeaders(auth),
            body: JSON.stringify({ searchText: searchText }),
        })
            .then((response) => response.json())
            //.then((jsonData) => addId(jsonData, "client_id"))
            .then((rowData) => {
                if (rowData["result"]) { auth.logOut(); return; }

                setGridData(rowData);
            })
            .catch((error) => console.log(error));
        //fetchData();

    }

    const handleAddRole = (event) => {
        navigate('/addrole');

    }

    useEffect(() => {

        if (!auth.user) {
            auth.logOut();
            return;
        }


        fetch("/api/roles", {
            method: "GET",
            headers: getHeaders(auth),
            //body: JSON.stringify(data),
        })
            .then((response) => response.json())
            //.then((jsonData) => addId(jsonData, "client_id"))
            .then((rowData) => {
                if (rowData["result"]) { auth.logOut(); return; }

                setGridData(rowData);
            })
            .catch((error) => console.log(error));
        //fetchData();
    }, []);

    return (
        <div style={{ width: '100%' }}>
            <div className="mb-3 row p-0">
                <label htmlFor="searchText" className="col-sm-2 col-form-label">Search Role:</label>
                <div className="col-sm-10 " style={{ flexFlow: "row", display: "flex" }}>
                    <input type="text" className="form-control" id="searchText" value={searchText} placeholder="Enter role name" onChange={changeSearchText}
                        onKeyDown={(e) => {
                            if (e.key === "Enter")
                                handleSearchBtn();
                        }} />
                    <span><button type="button" className="btn btn-success" onClick={handleSearchBtn}><SearchIcon /></button></span>
                </div>

            </div>

            <div className="m-1 row ">
                <div className="col">
                    <button type="submit" className="btn btn-primary mx-2" style={{ height: "50px" }}
                        onClick={handleAddRole}>Add Role</button>


                </div>

            </div>


            <DataGrid
                rows={gridData}
                columns={columns}
                checkboxSelection={false}
                disableMultipleRowSelection={true}
                columnGroupingModel={columnGroupingModel}
                density='compact'
                onRowClick={handleRowClick}
                pageSizeOptions={[5, 10, 25]}
                initialState={{
                    pagination: {
                        paginationModel: { pageSize: 5, page: 0 },
                    },
                }}
            />
        </div>
    );
}
