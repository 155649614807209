import React, { useState, useEffect } from 'react';

import { DataGrid } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';
import { useAuth } from "../../../hooks/AuthProvider";
import { getHeaders } from '../../../utils/apis';

const columns = [
    { field: 'tailorshop_id', headerName: 'ID', width: 90 },
    {
        field: 'name', headerName: 'Name',
        width: 150,
    },
    {
        field: 'short_name',
        headerName: 'Short name',
        width: 150,
    },
    {
        field: 'address',
        headerName: 'Address',
        width: 150,
    },
    {
        field: 'contact',
        headerName: 'Contact',
        width: 150,
    },
    {
        field: 'country',
        headerName: 'Country',
        width: 150,
    },
    {
        field: 'state',
        headerName: 'State',
        width: 150,
    },
    {
        field: 'email',
        headerName: 'Email',
        width: 150,
    },
    {
        field: 'notes',
        headerName: 'Notes',
        width: 150,
    },
    {
        field: 'tel',
        headerName: 'Telephone',
        width: 150,
    },
    {
        field: 'config',
        headerName: 'Config',
        width: 150,
    },
    {
        field: 'schema',
        headerName: 'Schema',
        width: 150,
    },
    {
        field: 'small_logo',
        headerName: 'Small Logo',
        width: 150,
    },
    {
        field: 'big_logo',
        headerName: 'Big Logo',
        width: 150,
    },

];


export default function TailorShopList(props) {
    const [searchText, setSearchText] = useState("");

    const [gridData, setGridData] = useState([]);

    const auth = useAuth();


    const addId = (collection, idName) => {
        return collection.map(item => {
            // console.log('item:', {
            //     ...item,
            //     id: item[idName],
            // });
            return {
                ...item,
                id: item[idName],
            };
        });
    };

    const changeSearchText = (event) => {
        setSearchText(event.target.value);
    }

    const handleRowClick = (params) => {
        // console.log('Click Row: ', params.row);
        props.selectTailorShop(params.row);
    };

    const handleSearchBtn = (event) => {

        if (searchText == null || searchText == undefined || searchText === "") {
            setSearchText(null);
        }

        fetch("/api/tailorshops/", {
            method: "POST",
            headers: getHeaders(auth),
            body: JSON.stringify({ searchText: searchText }),
        })
            .then((response) => response.json())
            //.then((jsonData) => addId(jsonData, "client_id"))
            .then((rowData) => {
                if (rowData["result"]) { auth.logOut(); return; }
                const rowData2 = addId(rowData, "tailorshop_id");
                setGridData(rowData2);
            })
            .catch((error) => console.error(error));


    }

    useEffect(() => {

        if (!auth.user) {
            auth.logOut();
            return;
        }


        fetch("/api/tailorshops", {
            method: "GET",
            headers: getHeaders(auth),
            //body: JSON.stringify(data),
        })
            .then((response) => response.json())

            .then((rowData) => {
                if (rowData["result"]) { auth.logOut(); return; }
                const rowData2 = addId(rowData, "tailorshop_id");
                setGridData(rowData2);
            })
            .catch((error) => console.error(error));

    }, []);

    return (
        <div style={{ width: '100%' }}>
            <div className="mb-3 row p-0">
                <label htmlFor="searchText" className="col-sm-2 col-form-label">Search tailor shop:</label>
                <div className="col-sm-10 " style={{ flexFlow: "row", display: "flex" }}>
                    <input type="text" className="form-control" id="searchText" value={searchText} placeholder="Enter tailor shop name or contact's email" onChange={changeSearchText}
                        onKeyDown={(e) => {
                            if (e.key === "Enter")
                                handleSearchBtn();
                        }} />
                    <span><button type="button" className="btn btn-success" onClick={handleSearchBtn}><SearchIcon /></button></span>
                </div>

            </div>

            <DataGrid
                rows={gridData}
                columns={columns}
                checkboxSelection={false}
                disableMultipleRowSelection={true}

                density='compact'
                onRowClick={handleRowClick}
                pageSizeOptions={[5, 10, 25]}
                initialState={{
                    pagination: {
                        paginationModel: { pageSize: 5, page: 0 },
                    },
                }}
            />
        </div>
    );
}