import React, { useState, useEffect } from 'react';

import { DataGrid } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';
import { useAuth } from "../../../hooks/AuthProvider";
import { getHeaders } from '../../../utils/apis';

const columns = [
    { field: 'client_id', headerName: 'Client ID', width: 90 },
    {
        field: 'first_name', headerName: 'First name',
        width: 150,
    },
    {
        field: 'last_name',
        headerName: 'Last name',
        width: 150,
    },
    {
        field: 'full_name',
        headerName: 'Full name',
        width: 150,
    },
    {
        field: 'nickname',
        headerName: 'Nick name',
        width: 150,
    }, {
        field: 'dob',
        headerName: 'DOB',
        width: 110,
    },
    {
        field: 'email',
        headerName: 'email',
        width: 150,
    },
    {
        field: 'telephone',
        headerName: 'Telephone',
        width: 150,
    },
    {
        field: 'country',
        headerName: 'Country',
        width: 150,
    },
    {
        field: 'remark',
        headerName: 'Remark',
        width: 150,
    },
    {
        field: 'state',
        headerName: 'State',
        width: 150,
    }, {
        field: 'orders_num',
        headerName: 'Num of orders',
        width: 150,
    }, {
        field: 'last_order_date',
        headerName: 'Last order date',
        width: 150,
    },

];

const columnGroupingModel = [
    {
        groupId: 'Internal',
        description: '',
        children: [{ field: 'id' }],
    },
    {
        groupId: 'Basic info',
        children: [
            {
                groupId: 'Full name',
                children: [{ field: 'last_name' }, { field: 'first_name' }],
            },
            { field: 'age' },
        ],
    },
];

export default function ClientsMan(props) {
    const [searchText, setSearchText] = useState("");

    const [gridData, setGridData] = useState([]);

    const auth = useAuth();

    const addId = (collection, idName) => {
        return collection.map(item => {
            // console.log('item:', {
            //     ...item,
            //     id: item[idName],
            // });
            return {
                ...item,
                id: item[idName],
            };
        });
    };

    const changeSearchText = (event) => {
        setSearchText(event.target.value);
    }

    const handleRowClick = (params) => {
        console.log('Click Row: ', params.row);
        props.selectClient(params.row, false);
    };

    const handleSearchBtn = (event) => {
        if (searchText == null || searchText == undefined || searchText === "") {
            setSearchText(null);
        }

        fetch("/api/searchclients/", {
            method: "POST",
            headers: getHeaders(auth),
            body: JSON.stringify({ searchText: searchText }),
        })
            .then((response) => response.json())
            //.then((jsonData) => addId(jsonData, "client_id"))
            .then((rowData) => {
                if (rowData["result"]) { auth.logOut(); return; }
                const rowData2 = addId(rowData, "client_id");
                setGridData(rowData2);
            })
            .catch((error) => console.log(error));
        //fetchData();

    }


    useEffect(() => {

        if (!auth.user) {
            auth.logOut();
            return;
        }


        fetch("/api/clients", {
            method: "GET",
            headers: getHeaders(auth),
            //body: JSON.stringify(data),
        })
            .then((response) => response.json())
            //.then((jsonData) => addId(jsonData, "client_id"))
            .then((rowData) => {
                if (rowData["result"]) { auth.logOut(); return; }
                const rowData2 = addId(rowData, "client_id");
                setGridData(rowData2);
            })
            .catch((error) => console.log(error));
        //fetchData();
    }, []);

    return (
        <div style={{ width: '100%' }}>
            <div className="mb-3 row p-0">
                <label htmlFor="searchText" className="col-sm-2 col-form-label">Search client:</label>
                <div className="col-sm-10 " style={{ flexFlow: "row", display: "flex" }}>
                    <input type="text" className="form-control" id="searchText" value={searchText} placeholder="Enter email, telephone or name" onChange={changeSearchText}
                        onKeyDown={(e) => {
                            if (e.key === "Enter")
                                handleSearchBtn();
                        }} />
                    <span><button type="button" className="btn btn-success" onClick={handleSearchBtn}><SearchIcon /></button></span>
                </div>

            </div>

            <DataGrid
                rows={gridData}
                columns={columns}
                checkboxSelection={false}
                disableMultipleRowSelection={true}
                columnGroupingModel={columnGroupingModel}
                density='compact'
                onRowClick={handleRowClick}
                pageSizeOptions={[5, 10, 25]}
                initialState={{
                    pagination: {
                        paginationModel: { pageSize: 5, page: 0 },
                    },
                }}
            />
        </div>
    );
}
