

import React, { useState, useEffect } from 'react';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Tabs from '@mui/material/Tabs';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import { DoModal, ModalContent } from "../../utils/Modal";
import { Height, Margin, Padding } from '@mui/icons-material';

import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { DataGrid } from '@mui/x-data-grid';

import { useAuth } from "../../hooks/AuthProvider";
import { getHeaders } from '../../utils/apis';

import '../users/UsersAndRoles.css';
import AvatarSelect from '../../components/avatarSelect';
import RolesMan from './RolesMan';


function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

const permColumns = [
    { field: 'group_id', headerName: 'ID', width: 90 },
    {
        field: 'group_name', headerName: 'Group Name',
        width: 350,
    },
    {
        field: 'notes',
        headerName: 'Notes',
        width: 350,
    },
    {
        field: 'state',
        headerName: 'State',
        width: 250,
    }, {
        field: 'group_type',
        headerName: 'Group Type',
        width: 250,
    }, {
        field: 'owner',
        headerName: 'Owner',
        width: 250,
    },
];


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function RoleAndPermissions() {
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const [roleData, setRoleData] = useState({});
    const [sysPermData, setSysPermData] = useState([]);


    // Roles
    const [checked, setChecked] = useState([]);
    const [left, setLeft] = useState([]);
    const [right, setRight] = useState([]);

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    //Group
    const [gridGroupData, setGridGroupData] = useState([]);

    const auth = useAuth();
    const onSubmit = data => {
        alert('Save group data');
    }

    const [isOpen, setIsopen] = useState(false);
    const showModal = () => setIsopen((prev) => !prev);

    const [tabValue, setTabValue] = useState(0);

    const childRef = useRef();

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handlePermToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handlePermAllRight = () => {
        setRight(right.concat(left));
        setLeft([]);
    };

    const handlePermCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handlePermCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const handlePermAllLeft = () => {
        setLeft(left.concat(right));
        setRight([]);
    };

    const handleSaveRolePermissions = () => {
        if (!roleData['role_id']) {
            return
        }

        let permissions = left.map(a => a.permission_id);

        console.log("permissions=", permissions);
        fetch("/api/roleperm/", {
            method: "POST",
            headers: getHeaders(auth),
            body: JSON.stringify({
                role_id: roleData['role_id'],
                permissions: permissions
            }),
        })
            .then((response) => response.json())
            //.then((jsonData) => addId(jsonData, "client_id"))
            .then((rowData) => {
                if (rowData["result"]) { auth.logOut(); return; }
                alert("Save role permission ok");
            })
            .catch((error) => console.log(error));
        //fetchData();

    }

    const customPermList = (items) => (
        <Paper sx={{ width: 400, height: 430, overflow: 'auto' }}>

            <List dense component="div" role="list">
                {items.map((e) => {
                    const labelId = `transfer-list-item-${e.role_id}-label`;

                    return (
                        <ListItemButton
                            key={e.permission_id}
                            role="listitem"
                            onClick={handlePermToggle(e)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(e) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={`${e.name}`} />
                        </ListItemButton>
                    );
                })}
            </List>
        </Paper>
    );

    useEffect(() => {

        if (!auth.user) {
            auth.logOut();
            return;
        }


        fetch("/api/permissions", {
            method: "GET",
            headers: getHeaders(auth),
            //body: JSON.stringify(data),
        })
            .then((response) => response.json())
            //.then((jsonData) => addId(jsonData, "client_id"))
            .then((rowData) => {
                if (rowData["result"]) { auth.logOut(); return; }

                setSysPermData(rowData);
                setRight(rowData);
            })
            .catch((error) => console.log(error));
        //fetchData();
    }, []);

    const loadPermission = (role_id) => {
        fetch("/api/roleperm/" + role_id, {
            method: "GET",
            headers: getHeaders(auth),
            //body: JSON.stringify(data),
        })
            .then((response) => response.json())
            //.then((jsonData) => addId(jsonData, "client_id"))
            .then((rowData) => {
                if (rowData["result"]) { auth.logOut(); return; }

                setLeft(rowData);

                let right = [];
                sysPermData.forEach((e) => {
                    let found = rowData.find((element) => element.permission_id === e.permission_id);
                    if (!found) {
                        right.push(e);
                    }
                });

                setRight(right);
            })
            .catch((error) => console.log(error));
        //fetchData();
    }

    const loadRoleGroups = (role_id) => {
        fetch("/api/rolegroups/" + role_id, {
            method: "GET",
            headers: getHeaders(auth),
            //body: JSON.stringify(data),
        })
            .then((response) => response.json())
            //.then((jsonData) => addId(jsonData, "client_id"))
            .then((rowData) => {
                if (rowData["result"]) { auth.logOut(); return; }

                setGridGroupData(rowData);
            })
            .catch((error) => console.log(error));
        //fetchData();
    }

    const selectRole = (data, next) => {
        setRoleData({ ...data });
        setValue("role_id", data['role_id']);
        setValue("role_name", data['role_name']);
        setValue("notes", data['notes']);

        // childRef.current?.callReloadData(data['group_id']);

        //setGroupData(data);
        loadPermission(data['role_id']);
        loadRoleGroups(data['role_id']);
    }

    return (
        <div style={{ margin: "30px" }}>
            {<RolesMan selectRole={selectRole} />}
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tabValue} onChange={handleChange} aria-label="User Tabs">
                        <Tab label="Role Info" {...a11yProps(0)} style={{ textTransform: 'none', }} />
                        <Tab label="Permissions" {...a11yProps(1)} style={{ textTransform: 'none', }} />
                        <Tab label="Groups have this roles" {...a11yProps(2)} style={{ textTransform: 'none', }} />
                    </Tabs>
                </Box>
                <CustomTabPanel value={tabValue} index={0}>
                    <h3> Role info</h3>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Container className="p-3 ">
                            <Row style={{ width: "100%" }}>
                                <Col md="6">
                                    <div className="mb-3">
                                        <label>Role ID:</label>&nbsp;<br />
                                        <input type="text" placeholder="group_id" {...register("role_id", { required: true })} disabled={true} />
                                    </div>

                                    <div className="mb-3">
                                        <label>Role Name:</label>&nbsp;<br />
                                        <input type="text" placeholder="group_name" {...register("role_name", {})} />
                                    </div>
                                    <div className="mb-3">
                                        <label>Notes:</label>&nbsp;<br />
                                        <input type="text" placeholder="notes" {...register("notes", {})} />
                                    </div>

                                </Col>
                            </Row>
                        </Container>
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </form>
                </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={1}>
                    <h3>Permissions</h3>
                    <Grid container spacing={2} justifyContent="center" alignItems="center">

                        <Grid item>Permissions for this role {customPermList(left)}</Grid>
                        <Grid item>
                            <Grid container direction="column" alignItems="center">
                                <Button
                                    sx={{ my: 0.5 }}
                                    variant="outlined"
                                    size="small"
                                    onClick={handlePermAllRight}
                                    disabled={left.length === 0}
                                    aria-label="move all right"
                                >
                                    ≫
                                </Button>
                                <Button
                                    sx={{ my: 0.5 }}
                                    variant="outlined"
                                    size="small"
                                    onClick={handlePermCheckedRight}
                                    disabled={leftChecked.length === 0}
                                    aria-label="move selected right"
                                >
                                    &gt;
                                </Button>
                                <Button
                                    sx={{ my: 0.5 }}
                                    variant="outlined"
                                    size="small"
                                    onClick={handlePermCheckedLeft}
                                    disabled={rightChecked.length === 0}
                                    aria-label="move selected left"
                                >
                                    &lt;
                                </Button>
                                <Button
                                    sx={{ my: 0.5 }}
                                    variant="outlined"
                                    size="small"
                                    onClick={handlePermAllLeft}
                                    disabled={right.length === 0}
                                    aria-label="move all left"
                                >
                                    ≪
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item>Permission available{customPermList(right)}</Grid>

                    </Grid>
                    <button type="button" className="btn btn-primary" onClick={handleSaveRolePermissions}>Save</button>
                </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={2}>
                    <h3>Groups</h3>

                    <DataGrid
                        rows={gridGroupData}
                        columns={permColumns}
                        checkboxSelection={false}
                        disableMultipleRowSelection={true}
                        //columnGroupingModel={columnGroupingModel}
                        density='compact'
                        // onRowClick={handleRowClick}
                        pageSizeOptions={[5, 10, 25]}
                        initialState={{
                            pagination: {
                                paginationModel: { pageSize: 5, page: 0 },
                            },
                        }}
                    />
                </CustomTabPanel>

            </Box>




        </div >
    );
}




