import React, { useState, useEffect, useRef, setState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from "react-router-dom";


import ReactDatePicker from "react-datepicker";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ReactFlagsSelect from "react-flags-select";
import { useAuth } from "../../hooks/AuthProvider";
import { getHeaders } from '../../utils/apis';
import { DoModal, ModalContent } from "../../utils/Modal";

import './AddModGroup.css';


export default function AddModGroup(props) {
    const { register, handleSubmit, control, watch, formState: { errors }, reset } = useForm();

    const [tailorShops, setTailorShops] = useState([]);
    const [tailorshop, setTailorShop] = useState({ tailorshop_id: "1", short_name: "YB" });

    const navigate = useNavigate();

    const auth = useAuth();
    if (!auth.user) {
        auth.logOut();

    }

    const handleTailorShopSelect = (event) => {
        if (event.target.value === "system") {
            setTailorShop({ tailorshop_id: "0", short_name: "System" });
            console.log('TS:', { tailorshop_id: "0", short_name: "System" });
            return;
        }
        let ts = tailorShops[event.target.value];
        if (ts) {
            console.log('TS:', ts);
            setTailorShop(ts);
        }
    }

    const onSubmit = (data) => {

        if (!auth.user) {
            auth.logOut();
            return;
        }


        data['owner'] = tailorshop.tailorshop_id;

        // console.debug("DEBUG:", data);

        fetch("/api/checkexists", {
            method: "POST",
            headers: getHeaders(auth),
            body: JSON.stringify({
                name: data['group_name'],
                type: "groups"
            }),
        }).then((response) => response.json())
            .then((rowData) => {
                if (rowData["result"]) {
                    auth.logOut();
                }
                console.log('Check', rowData);
                if (rowData.length >= 1) {

                    alert("Group " + data['group_name'] + " already existed");
                    return;
                }

                // Add new user
                fetch("/api/addgroup", {
                    method: "POST",
                    headers: getHeaders(auth),
                    body: JSON.stringify(data),
                })
                    .then((response) => response.json())
                    .then((rowData) => {
                        if (rowData["result"]) {
                            if (rowData["result"] === "timeout") {
                                auth.logOut();
                            } else if (rowData["result"] === "OK") {
                                //     console.log("Add new return:", rowData[0]);

                                alert("Add group successful");
                                //props.newclientUpdate(data, true);
                                reset();
                                navigate(-1);
                            } else {

                                alert("Error when query DB");
                            }
                        } else {
                            console.log("Add new group return:", rowData[0]);

                            alert("Add group successful");
                            //props.newclientUpdate(data, true);
                            reset();
                        }
                        // console.log(rowData);
                    })
                    .catch((error) => { console.log(error); alert("Error call API"); });
            })
            .catch((error) => { console.log(error); alert("Error call API"); });

    }

    useEffect(() => {

        if (!auth.user) {
            auth.logOut();
            return;
        }


        fetch("/api/tailorshops/", {
            method: "GET",
            headers: getHeaders(auth),
        })
            .then((response) => response.json())
            //.then((jsonData) => addId(jsonData, "client_id"))
            .then((rowData) => {
                if (rowData["result"]) { auth.logOut(); return; }
                //getOrderItems(rowData2);
                //console.log(rowData);
                setTailorShops(rowData);

                if (rowData.length >= 1) {
                    setTailorShop(rowData[0]);
                }

            })
            .catch((error) => console.log(error));

    }, []);


    return (
        <div className="d-flex justify-content-center">
            <form onSubmit={handleSubmit(onSubmit)}>
                <Container className="p-9 ">

                    <Row style={{ width: "100%" }}>
                        <label className="form-select-label p-1" htmlFor="tailorshop"> Select Tailor Shop:</label>
                        <select className="form-select m-2 " aria-label="Tailor shop" style={{ height: "55px", width: "450px" }} id="tailorshop"
                            onChange={handleTailorShopSelect}>
                            {/* <option value="0" id="0" defaultValue>All</option> */}
                            <option value="system" key="system">All System</option>

                            {tailorShops.map((shop, idx) => {
                                return (
                                    <option value={idx} key={shop.tailorshop_id}>{shop.tailorshop_id} - {shop.name} ({shop.short_name})</option>
                                );
                            })}
                        </select>
                    </Row>

                    <Row style={{ width: "100%" }}>

                        <div className="mb-3">
                            <label htmlFor="group_name">Group Name *:</label>&nbsp;<br />
                            <input id="group_name" type="text" placeholder="Group name" {...register("group_name", { required: true })} />
                        </div>

                        <div className="mb-3">
                            <label htmlFor="notes">Notes:</label>&nbsp;<br />

                            <textarea id="notes" name="notes" rows="3" cols="20" {...register("notes", {})}>
                            </textarea>
                        </div>

                    </Row>

                </Container>
                <button type="submit" className="btn btn-primary btn-sm">Add Group</button>

            </form>
        </div>
    );
}




