import React, { useState, useEffect, useRef, setState } from 'react';
import { useAuth } from "../../hooks/AuthProvider";
import { getHeaders } from '../../utils/apis';
import { ReactSpreadsheetImport } from "react-spreadsheet-import";

import { DataGrid } from '@mui/x-data-grid';
import { darken, lighten, styled } from '@mui/material/styles';


const getBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

const getSelectedBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedHoverBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);



const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .super-app-theme--10': {
        backgroundColor: getBackgroundColor(theme.palette.warning.main, theme.palette.mode),

        '&:hover': {
            backgroundColor: getHoverBackgroundColor(
                theme.palette.warning.main,
                theme.palette.mode,
            ),
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(
                theme.palette.info.main,
                theme.palette.mode,
            ),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(
                    theme.palette.success.main,
                    theme.palette.mode,
                ),
            },
        },
    },
    '& .super-app-theme--20': {
        backgroundColor: getBackgroundColor(theme.palette.success.main, theme.palette.mode),

        '&:hover': {
            backgroundColor: getHoverBackgroundColor(
                theme.palette.info.main,
                theme.palette.mode,
            ),
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(
                theme.palette.info.main,
                theme.palette.mode,
            ),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(
                    theme.palette.success.main,
                    theme.palette.mode,
                ),
            },
        },
    },
    '& .super-app-theme--30': {
        backgroundColor: getBackgroundColor(
            theme.palette.secondary.main,
            theme.palette.mode,
        ),
        color: "red",
        '&:hover': {
            backgroundColor: getHoverBackgroundColor(
                theme.palette.secondary.main,
                theme.palette.mode,
            ),
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(
                theme.palette.secondary.main,
                theme.palette.mode,
            ),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(
                    theme.palette.secondary.main,
                    theme.palette.mode,
                ),
            },
        },
    },
    '& .super-app-theme--40': {
        // backgroundColor: getBackgroundColor(
        //     theme.palette.warning.main,
        //     theme.palette.mode,
        // ),
        '&:hover': {
            backgroundColor: getHoverBackgroundColor(
                theme.palette.warning.main,
                theme.palette.mode,
            ),
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(
                theme.palette.warning.main,
                theme.palette.mode,
            ),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(
                    theme.palette.warning.main,
                    theme.palette.mode,
                ),
            },
        },
    },
    '& .super-app-theme--50': {
        backgroundColor: getBackgroundColor(
            theme.palette.error.main,
            theme.palette.mode,
        ),
        '&:hover': {
            backgroundColor: getHoverBackgroundColor(
                theme.palette.error.main,
                theme.palette.mode,
            ),
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(
                theme.palette.error.main,
                theme.palette.mode,
            ),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(
                    theme.palette.error.main,
                    theme.palette.mode,
                ),
            },
        },
    },
}));

const fields_mau = [
    {
        // Visible in table header and when matching columns.
        label: "Name",
        // This is the key used for this field when we call onSubmit.
        key: "name",
        // Allows for better automatic column matching. Optional.
        alternateMatches: ["first name", "first"],
        // Used when editing and validating information.
        fieldType: {
            // There are 3 types - "input" / "checkbox" / "select".
            type: "input",
        },
        // Used in the first step to provide an example of what data is expected in this field. Optional.
        example: "Stephanie",
        // Can have multiple validations that are visible in Validation Step table.
        validations: [
            {
                // Can be "required" / "unique" / "regex"
                rule: "required",
                errorMessage: "Name is required",
                // There can be "info" / "warning" / "error" levels. Optional. Default "error".
                level: "error",
            },
        ],
    },
];
const fields = [
    {
        label: "Order Id",
        key: "order_id",
        alternateMatches: ["orderid"],
        fieldType: { type: "input" },
        example: "YB101",
        validations: [{ rule: "required", errorMessage: "Order ID is required", level: "error", }],
    },
    {
        label: "Fabric Code",
        key: "fabric_code",
        alternateMatches: ["fabriccode"],
        fieldType: { type: "input" },
        example: "PF1",
        validations: [{ rule: "required", errorMessage: "Fabric Code is required", level: "error", }],
    },
    {
        label: "Collar",
        key: "collar",
        alternateMatches: ["collar"],
        fieldType: { type: "input" },
        example: "40",
        validations: [{ rule: "required", errorMessage: "Collar is required", level: "error", }],
    },
    {
        label: "Chest",
        key: "chest",
        alternateMatches: ["chest"],
        fieldType: { type: "input" },
        example: "140",
        validations: [{ rule: "required", errorMessage: "Chest is required", level: "error", }],
    },
    {
        label: "Waist",
        key: "waist",
        alternateMatches: ["waist"],
        fieldType: { type: "input" },
        example: "122",
        validations: [{ rule: "required", errorMessage: "Waist is required", level: "error", }],
    },
    {
        label: "Hip",
        key: "hip",
        alternateMatches: ["hip"],
        fieldType: { type: "input" },
        example: "136",
        validations: [{ rule: "required", errorMessage: "Hip is required", level: "error", }],
    },
    {
        label: "Shoulders",
        key: "shoulders",
        alternateMatches: ["shoulders"],
        fieldType: { type: "input" },
        example: "56",
        validations: [{ rule: "required", errorMessage: "Shoulders is required", level: "error", }],
    },
    {
        label: "Sleeve Length",
        key: "sleeve_length",
        alternateMatches: ["sleeve"],
        fieldType: { type: "input" },
        example: "72",
        validations: [{ rule: "required", errorMessage: "Sleeve length is required", level: "error", }],
    },
    {
        label: "Upper Arm",
        key: "upper_arm",
        alternateMatches: ["upper_arm"],
        fieldType: { type: "input" },
        example: "90",
        validations: [{ rule: "required", errorMessage: "Upper Arm is required", level: "error", }],
    },
    {
        label: "Wrist",
        key: "wrist",
        alternateMatches: ["wrist"],
        fieldType: { type: "input" },
        example: "90",
        validations: [{ rule: "required", errorMessage: "Wrist is required", level: "error", }],
    },
    {
        label: "Back length",
        key: "back_length",
        alternateMatches: ["back_length_casual", "back_length_business"],
        fieldType: { type: "input" },
        example: "90",
        validations: [{ rule: "required", errorMessage: "Back length is required", level: "error", }],
    },

]
/*
order_id
fabric_code
collar
chest
waist
hip
shoulders
sleeve_length
upper_arm
wrist
back_length_casual
back_length_business
*/


const columns = [
    { field: 'order_id', headerName: 'Order ID', width: 90 },
    { field: 'pattern', headerName: 'pattern', width: 90 },
    {
        field: 'fabric_code',
        headerName: 'Fabric code',
        width: 150,
    },
    {
        field: 'collar',
        headerName: 'Collar',
        width: 150,
    },
    {
        field: 'chest',
        headerName: 'Chest',
        width: 150,
    },
    {

        field: 'waist',
        headerName: 'Waist',
        width: 100,
    }, {
        field: 'hip',
        headerName: 'Hip',
        width: 100,
    }, {
        field: 'shoulders',
        headerName: 'Shoulders',
        width: 100,
    }, {
        field: 'sleeve_length',
        headerName: 'Sleeve Length',
        width: 100,
    }, {
        field: 'upper_arm',
        headerName: 'Upper Arm',
        width: 100,
    }, {
        field: 'wrist',
        headerName: 'Wrist',
        width: 180,
    },
    {
        field: 'back_length_casual',
        headerName: 'Back Length Casual',
        width: 180,
    },
    {
        field: 'back_length_business',
        headerName: 'Back Length Business',
        width: 180,
    },

];

export default function PatternSearch(props) {
    let [isOpen, setIsopen] = useState(true);
    let [validData, setData] = useState([]);

    let [returnData, setReturnData] = useState([]);
    let [isWait, setWait] = useState(true);

    const auth = useAuth();

    if (auth.user == null) {
        auth.logOut();
        // return;
    }

    // Determines if modal is visible.


    // Called when flow is closed without reaching submit.
    const onClose = () => {
        setIsopen(false);
    };

    // Called after user completes the flow. Provides data array, where data keys matches your field keys. 
    const onSubmit = async (data, file) => {
        if (auth.user == null) {
            auth.logOut();
            // return;
        }

        setIsopen(false);
        setData(data.validData);
        console.log(data);
        data.validData.forEach((row, idx) => {
            row['id'] = idx;
            row['pattern'] = 'Wait...';
        });
        setReturnData(data.validData);

        fetch("/api/patternsearch/", {
            method: "POST",
            headers: getHeaders(auth),
            body: JSON.stringify({
                data: data.validData
            }),
        })
            .then((response) => response.json())
            //.then((jsonData) => addId(jsonData, "client_id"))
            .then((rowData) => {
                if (rowData["result"]) { auth.logOut(); return; }

                setReturnData(rowData);
                setWait(false);
            })
            .catch((error) => console.log(error));
        //fetchData();


    }


    return (<>
        <h1>Pattern Search</h1>
        <ReactSpreadsheetImport isOpen={isOpen} onClose={onClose} onSubmit={onSubmit} fields={fields} />
        Import Data:
        {validData.length} records

        {validData.length >= 1 && <>
            {isWait ?
                <><br /><span style={{ color: "blue", fontWeight: "bold" }}>Please wait...</span></>
                :
                <><br /><span style={{ color: "red", fontWeight: "bold" }}>FINISHED</span></>
            }
        </>
        }
        <StyledDataGrid
            rows={returnData}
            columns={columns}
            checkboxSelection={true}
            disableMultipleRowSelection={true}
            // columnGroupingModel={columnGroupingModel}
            density='compact'
            //  onRowClick={handleRowClick}
            // onRowSelectionModelChange={handleSelectionChange}

            pageSizeOptions={[5, 10, 25, 50, 100]}
            initialState={{
                pagination: {
                    paginationModel: { pageSize: 25, page: 0 },
                },
            }}
            getRowClassName={(params) => `super-app-theme--${params.row.state}`}
        />

    </>);
}