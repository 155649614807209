import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
    },
    section: {
        border: 1,
        margin: 10,
        padding: 10,
        textAlign: 'left',
        flexGrow: 2
    }
});


export default function ExportToPdf(props) {
    const finishRender = () => {
        // alert("PDF File is ready");
    }

    return (
        <Document author="mtm.shirt.vn"
            title="Shirt Order #123"
            onRender={finishRender}>
            <Page size="A4" style={styles.page} >
                <View style={styles.section}>

                    <Text>Section #1</Text>
                    <table style={{ border: "1px solid" }}>
                        <tbody>
                            <tr> <td><Text>1</Text></td><td><Text>2</Text></td>
                            </tr>
                            <tr> <td><Text>3</Text></td><td><Text>4</Text></td>
                            </tr>
                        </tbody>
                    </table>

                </View>
                <View style={styles.section}>
                    <Text>Section #2</Text>
                </View>
            </Page>
        </Document>
    )
};