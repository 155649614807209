import React, { useState, useEffect, useRef, setState } from 'react';
import { useTheme } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import { tokens } from "../theme";
import { mockBarData as data } from "../data/mockData";

import { useAuth } from "../../../hooks/AuthProvider";
import { getHeaders } from '../../../utils/apis';

const colorsArray = [
    "hsl(307, 70%, 50%)", "hsl(111, 70%, 50%)", "hsl(273, 70%, 50%)", "hsl(275, 70%, 50%)",
    "hsl(72, 70%, 50%)", "hsl(96, 70%, 50%)", "hsl(106, 70%, 50%)", "hsl(256, 70%, 50%)",
    "hsl(257, 70%, 50%)", "hsl(326, 70%, 50%)", "hsl(110, 70%, 50%)", "hsl(9, 70%, 50%)",
    "hsl(190, 70%, 50%)", "hsl(325, 70%, 50%)", "hsl(54, 70%, 50%)", "hsl(285, 70%, 50%)"];

const BarChart = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [statData, setStatData] = useState([]);
    const [fabricList, setFabricList] = useState([]);

    const auth = useAuth();

    useEffect(() => {


        fetch("/api/rptAllFabricByDate/", {
            method: "GET",
            headers: getHeaders(auth),
            //body: JSON.stringify(data),
        })
            .then((response) => response.json())
            //.then((jsonData) => addId(jsonData, "client_id"))
            .then((rowData) => {
                if (rowData["result"]) { auth.logOut(); return; }
                //setFabricStatData(rowData);


                let shops = [];
                setFabricList(rowData.map(e => e.fabric_code));
                shops = rowData.map(e => { return ({ tailorshop_id: e.tailorshop_id, short_name: e.short_name }) });

                let fabrics = rowData.map((e, idx) => {  // Phuc vu tinh color
                    return ({
                        fabric_code: e.fabric_code,
                        color: colorsArray[idx % colorsArray.length]
                    })
                });

                rowData.map(e => {
                    let fabric = fabrics.find(f => f.fabric_code === e.fabric_code);
                    shops.map(ts => {
                        if (ts.tailorshop_id === e.tailorshop_id) {
                            if (ts[e.fabric_code]) { // Da co thi add them
                                ts[e.fabric_code] = ts[e.fabric_code] + parseInt(e.num_of_shirts);
                            } else { // Chua xuat hien lan nao
                                ts[e.fabric_code] = parseInt(e.num_of_shirts);
                                ts[e.fabric_code + "Color"] = fabric.color;
                            }
                        } else {
                            if (!ts[e.fabric_code]) { // Neu khong co ma vai, thi add vao
                                ts[e.fabric_code] = 0;
                                ts[e.fabric_code + "Color"] = fabric.color;
                            }
                        }
                    });

                })

                //console.debug(shops);
                setStatData(shops);


            })
            .catch((error) => console.log(error));


        //console.debug(props.data);


    }, []);

    return (
        <ResponsiveBar
            data={statData}
            theme={{
                // added
                axis: {
                    domain: {
                        line: {
                            stroke: colors.grey[100],
                        },
                    },
                    legend: {
                        text: {
                            fill: colors.grey[100],
                        },
                    },
                    ticks: {
                        line: {
                            stroke: colors.grey[100],
                            strokeWidth: 1,
                        },
                        text: {
                            fill: colors.grey[100],
                        },
                    },
                },
                legends: {
                    text: {
                        fill: colors.grey[100],
                    },
                },
            }}
            keys={fabricList}
            indexBy="short_name"
            margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
            padding={0.3}
            valueScale={{ type: "linear" }}
            indexScale={{ type: "band", round: true }}
            colors={{ scheme: "nivo" }}
            defs={[
                {
                    id: "dots",
                    type: "patternDots",
                    background: "inherit",
                    color: "#38bcb2",
                    size: 4,
                    padding: 1,
                    stagger: true,
                },
                {
                    id: "lines",
                    type: "patternLines",
                    background: "inherit",
                    color: "#eed312",
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10,
                },
            ]}
            borderColor={{
                from: "color",
                modifiers: [["darker", "1.6"]],
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Tailorshops",
                legendPosition: "middle",
                legendOffset: 32,
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Fabrics",
                legendPosition: "middle",
                legendOffset: -40,
            }}
            enableLabel={false}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{
                from: "color",
                modifiers: [["darker", 1.6]],
            }}
            legends={[
                {
                    dataFrom: "keys",
                    anchor: "bottom-right",
                    direction: "column",
                    justify: false,
                    translateX: 120,
                    translateY: 0,
                    itemsSpacing: 2,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemDirection: "left-to-right",
                    itemOpacity: 0.85,
                    symbolSize: 20,
                    effects: [
                        {
                            on: "hover",
                            style: {
                                itemOpacity: 1,
                            },
                        },
                    ],
                },
            ]}
            role="application"
            barAriaLabel={function (e) {
                return e.id + ": " + e.formattedValue + " in country: " + e.indexValue;
            }}
        />
    );
};

export default BarChart;
