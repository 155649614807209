import React, { useState, useEffect } from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import SearchIcon from '@mui/icons-material/Search';
import Rating from '@mui/material/Rating';
import { useAuth } from "../../../hooks/AuthProvider";
import { getHeaders } from '../../../utils/apis';
import Collapse from '@mui/material/Collapse';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import { useSpring, animated } from '@react-spring/web';


import './pipingColorSelect.css';
import { styled } from '@mui/material';


export default function PipingColorSelector(props) {

    const [searchText, setSearchText] = useState("");
    const [activeButton, setActiveButton] = useState("");

    // const [viewButtonHole, setViewButtonHole] = useState([]);
    const pipingColor_none = { pipingcolor_id: "0", fabric: "none", name: "None", color: "#ffffff" };
    const pipingColorData = [
        { pipingcolor_id: "1", name: "White", color: "#ffffff", fabric: "P1" },
        { pipingcolor_id: "2", name: "Orange", color: "#f97238", fabric: "Z7" },
        { pipingcolor_id: "3", name: "Yellow", color: "#ffeb88", fabric: "Z4" },
        { pipingcolor_id: "4", name: "Turquoise", color: "#bcf5f7", fabric: "Z2" },
        { pipingcolor_id: "5", name: "Light blue", color: "#b6cbfb", fabric: "Z13" },
        { pipingcolor_id: "0", fabric: "none", name: "None", color: "#ffffff" },
        { pipingcolor_id: "6", name: "Pink", color: "#fec4e5", fabric: "Z9" },
        { pipingcolor_id: "7", name: "Red", color: "#d50305", fabric: "P22" },
        { pipingcolor_id: "8", name: "Camel", color: "#e6c989", fabric: "Z17" },
        { pipingcolor_id: "9", name: "Light green", color: "#c7e788", fabric: "Z5" },
        { pipingcolor_id: "10", name: "Blue", color: "#445fd4", fabric: "P11C" },
        { pipingcolor_id: "11", name: "Grey", color: "#9f9f9f", fabric: "Z11" },
        { pipingcolor_id: "12", name: "Hot pink", color: "#e20471", fabric: "P8" },
        { pipingcolor_id: "13", name: "Purple", color: "#60056d", fabric: "Z3" },
        { pipingcolor_id: "14", name: "Brown", color: "#5f4231", fabric: "P20" },
        { pipingcolor_id: "15", name: "Green", color: "#5d744f", fabric: "Z6" },
        { pipingcolor_id: "16", name: "Navy", color: "#0a266d", fabric: "P18" },
        { pipingcolor_id: "17", name: "Black", color: "#000000", fabric: "P24" },

    ]
    /*
-White	ffffff	P1
-Pink	fec4e5	Z9
Hot pink	e20471	P8
-Orange	f97238	Z7
-Red	d50305	P22
Purple	60056d	Z3
-Yellow	ffeb88	Z4
-Camel	e6c989	Z17
Brown	5f4231	P20
-Turquoise	bcf5f7	Z2
-Light green	c7e788	Z5
Green	5d744f	Z6
-Light blue	b6cbfb	Z13
-Blue	445fd4	P11C
Navy	0a266d	P18
-rey	9f9f9f	Z11
Black	'000000	P24
    */
    const auth = useAuth();
    if (auth.user === null || auth.user === undefined) {
        auth.logOut();
        // return;
    }

    const curData = props.orderData;


    const handleColorNoneClick = (event) => {
        props.selectPipingColor(pipingColor_none, true);
    }

    const handleImgClick = (event) => {

        setActiveButton(event.target.id);

        let pipingColor = pipingColorData.find(obj => obj['pipingcolor_id'] === event.target.id);
        // console.debug("DEBUG: Buttons=", button)

        props.selectPipingColor(pipingColor, true);
        // //   console.log(event.target);
    }



    useEffect(() => {

        // fetch("/api/buttons/1", {
        //     method: "GET",
        //     headers: getHeaders(auth),
        //     //body: JSON.stringify(data),
        // })
        //     .then((response) => response.json())
        //     .then((rowData) => {
        //         if (rowData["result"]) { auth.logOut(); return;}
        //         // const rowData2 = addId(rowData, "client_id");
        //         // setFabricsData(rowData);
        //         console.debug(rowData);
        //         setViewButton(rowData);
        //     })
        //     .catch((error) => console.log(error));
        // //fetchData();
    }, []);

    return (
        <>
            <div className='container-sm'>
                <div className='img-container mb-5'  >
                    <div className="color-item-sel">
                        <div title={pipingColor_none.name} className="color-img-sel" style={{ backgroundColor: pipingColor_none.color }} onClick={handleColorNoneClick}></div>
                    </div>
                    {pipingColor_none.name}
                    <br />
                </div>

                <ImageList sx={{ width: "100%", height: "500px" }} cols={6}>
                    {pipingColorData && pipingColorData.map((item) => (
                        <ImageListItem key={item.pipingcolor_id}>
                            {/* <div className='img-container'  >
                                <img
                                    srcSet={`${item.small_img}?auto=format&dpr=2 2x`}
                                    src={`${item.small_img}?auto=format`}
                                    alt={item.full_name}
                                    id={item.button_code}
                                    onClick={handleImgClick}
                                    style={{ display: "blocks" }}
                                    //, borderRadius: "10%", boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.4)"
                                    className='holder'
                                />
                                <br />
                                {item.button_code}
                                <div id={item.button_code} className="overlay" onClick={handleImgClick}>Click to select</div>
                            </div> */}

                            <div className='img-container'  >
                                <div className="color-item-sel">
                                    <div title={item.name + " (" + item.fabric + ")"} className="color-img-sel" style={{ backgroundColor: item.color }}
                                        onClick={handleImgClick} id={item.pipingcolor_id}></div>
                                </div>
                                {item.name}  ({item.fabric})
                            </div>

                            {/* {(activeButton === item.color) ? <ImageListItemBar
                                title={item.name}
                                // subtitle={<span>code: {item.code}</span>}
                                position="below"
                                sx={{
                                    "& .MuiImageListItemBar-title": { color: "white", border: "1px solid blue", backgroundColor: "#1976d2" }, //styles for title
                                }} />
                                : <ImageListItemBar
                                    title={item.name}
                                    // subtitle={<span>code: {item.code}</span>}
                                    position="below"
                                    sx={{
                                        "& .MuiImageListItemBar-title": { color: "black" }, //styles for title
                                    }} />
                            } */}

                        </ImageListItem >
                    ))}
                </ImageList >



            </div >


        </>
    );
}